import { Box, Typography, Menu, MenuItem, IconButton } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState, useMemo, useEffect } from "react";
import { useAuthStore } from "../features/auth/authStore";
import { useFetchOrganizations } from "../hooks/useFetchOrganizations";
import { IOrganization } from "../features/organization-manageent/organizationInterfaces";

export interface IOrgSelect{
    id: string; 
    name: string;
    default_estimate_options:string[];
}
interface Props {
    onSelect?: (val: IOrgSelect) => void;
}

const OrganizationSelector: React.FC<Props> = ({ onSelect }) => {
    const [fetchOrg, setFetchOrg] = useState(false);
    const authUser = useAuthStore(state => state.authUser);
    const { data: organizations } = useFetchOrganizations(fetchOrg);
    const [orgMenuAnchorEl, setOrgMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedOrg, setSelectedOrg] = useState<IOrgSelect>({ id: 'all', name: 'All', default_estimate_options:[] });

    const orgData = useMemo(() => {
        const allOption = { id: 'all', name: 'All', default_estimate_options:[] };
        const fetchedOrgs: IOrgSelect[] = organizations?.data?.map((org: IOrganization) => ({
            id: org.id,
            name: org.name,
            default_estimate_options: org.default_estimate_options
        })) || [];

        if (authUser?.organizations && !fetchOrg) {
            const userOrgs = authUser.organizations.map((org) => ({ id: org.id, name: org.name, default_estimate_options:org.default_estimate_options }));
            return [allOption, ...userOrgs];
        }

        return [allOption, ...fetchedOrgs];
    }, [authUser, organizations, fetchOrg]);

    const handleOrgMenuOpen = (event: any) => setOrgMenuAnchorEl(event.currentTarget);

    const handleOrgMenuClose = (org: IOrgSelect) => {
        if (org.id !== selectedOrg.id) {
            // console.log(org);
            setSelectedOrg(org);
            if (onSelect) onSelect(org);
        }
        setOrgMenuAnchorEl(null);
    };

    useEffect(() => {
        if (authUser?.role === 'super_admin' || authUser?.role === 'appraiser') {
            setFetchOrg(true);
        }
    }, [authUser]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <img 
                src={authUser?.role === 'super_admin' || authUser?.role === 'appraiser' ? '/assets/images/org.png' : '/assets/images/org-disable.png'} 
                alt='org' 
                width={40} 
                height={40} 
            />
            <Typography sx={{ opacity: authUser?.role === 'super_admin' || authUser?.role === 'appraiser' ? 1 : 0.5 }}>
                {selectedOrg.name}
            </Typography>
            {(authUser?.role === 'super_admin'||authUser?.role === 'appraiser') && (
                <IconButton onClick={handleOrgMenuOpen}>
                    <ArrowDropDownIcon />
                </IconButton>
            )}
            <Menu anchorEl={orgMenuAnchorEl} open={Boolean(orgMenuAnchorEl)} onClose={() => setOrgMenuAnchorEl(null)}>
                {orgData.map((org) => (
                    <MenuItem key={org.id} onClick={() => handleOrgMenuClose(org)}>
                        {org.name}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default OrganizationSelector;
