import { Box, Grid, Modal, Typography, IconButton } from "@mui/material";
import React, { useState } from "react";
import { MaterialSymbol } from "react-material-symbols";
import { APIconButton } from "../../components/buttons";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useForm } from "react-hook-form";
import { LabelInput, SelectInput } from "../../components/inputs";
import { ASSET_HEADERS } from "../../Constant";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (val: any) => void;
}

const AssetSearchModal: React.FC<Props> = ({ open, onClose, onSubmit }) => {
  const { control, handleSubmit, setValue, reset, getValues } = useForm<any>();

  const [filters, setFilters] = useState([{ field: '', value: '' }]);

  const addFilter = () => {
    if (filters.length < ASSET_HEADERS.length) {
      setFilters([...filters, { field: '', value: '' }]);
    }
  };

  const removeFilter = (index: number) => {
    const currenfilters = getValues('filters').filter((item:any,i:number)=>index !== i);
    setValue('filters',currenfilters);
    const updatedFilters = filters.filter((_, i) => i !== index);
    setFilters(updatedFilters);
  };

  const getAvailableOptions = (index: number) => {
    const selectedFields = filters.map((filter, i) => (i !== index ? filter.field : null));
    return ASSET_HEADERS.filter(header => !selectedFields.includes(header.value));
  };

  const handleClose = () => {
    setFilters([{ field: '', value: '' }]);
    reset();
    onClose();
  };

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                backgroundColor: '#FCFCFC',
                p: '25px',
                borderRadius: '8px 8px 0px 0px',
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: '24px', color: '#000000' }}>
                Search Plus
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Box>
                  <APIconButton
                    text="Cancel"
                    icon={<MaterialSymbol icon="close" />}
                    onClick={handleClose}
                    whiteBackground
                  />
                </Box>
                <APIconButton
                  text={'Find'}
                  icon={<MaterialSymbol icon="east" />}
                  type="submit"
                />
              </Box>
            </Box>

            <Box sx={{ width: '100%', p: '25px' }}>
              <Grid container spacing={2}>
                {filters.map((filter, index) => (
                  <React.Fragment key={filter.field}>
                    {index > 0 && (
                      <Grid item xs={12}>
                        <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                          AND
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <SelectInput
                        id={`field-${index}`}
                        label={index === 0 ? "Where" : ""}
                        name={`filters[${index}].field`}
                        options={getAvailableOptions(index).map(option => ({
                          label: option.label,
                          value: option.value
                        }))}
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                      <LabelInput
                        id={`value-${index}`}
                        label={index === 0 ? "Is" : ""}
                        name={`filters[${index}].value`}
                        control={control}
                      />
                      <IconButton onClick={() => removeFilter(index)} disabled={filters.length === 1}>
                        <MaterialSymbol icon="delete" />
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box sx={{width:'50%'}}>
                    <APIconButton
                        text="Add Filter"
                        whiteBackground
                        icon={<MaterialSymbol icon="add" />}
                        onClick={addFilter}
                        isDisabled={filters.length >= ASSET_HEADERS.length}
                    />
                    </Box>
                </Grid>
              </Grid>
            </Box>
          </ValidatorForm>
        </Box>
      </Box>
    </Modal>
  );
};

export default AssetSearchModal;
