import { useNavigate } from "react-router-dom";
import APTable from "../../components/table/APTable";
import { IColDef } from "../../components/table/tableInterfaces";
import { useRequestStore } from "./requestStore";
import { IPagination, ITableData } from "../../interfaces";
import { useMutation } from "@tanstack/react-query";
import { createReport } from "../reports/reportQueries";
import { handleError } from "../../utils/errorHandler";
import { toast } from "react-toastify";

interface Props{
    isLoading?: boolean;
    data: ITableData;
    rowPerPage?:number;
    onRowSelection?:(item:any,details:any) => void;
    onDelete?:(row:any)=>void;
    onPageChange?: (val:IPagination)=>void;
}
const RequestTable:React.FC<Props> = ({isLoading, data, rowPerPage, onRowSelection, onDelete, onPageChange}) => {
  const navigate = useNavigate();
  const storeSelectedRequest = useRequestStore(state=>state.storeSelectedRequest)
  const { isPending: isCreatingReporting, mutate: createReportMutation } = useMutation({
    mutationKey: ['create report'],
    mutationFn: (requestId: any) => createReport(requestId),
    onSuccess: (res,variables) => {
      console.log('variables: ',variables);
      console.log('res: ',res.data);
      //show modal retrieving modal
      navigate(`/reports`,{state:{reportResponse:res.data}})
    },
    onError: handleError,
  });
    const columns: IColDef[] = [
        // { field: 'id', 
        //   headerName: 'ID',
        // },
        {
          field: 'author.name',
          headerName: 'Created by',
        },
        {
          field: 'customer_name',
          headerName: 'Asset owner',
        },
        {
          field: 'assets_count',
          headerName: 'Assets',
          filterable:false,
          sortable: false,
        },
        {
          field: 'media_count',
          headerName: 'Photo/Videos',
          type:'media',
          filterable:false,
          sortable: false,
        },
        {
          field: 'created_at',
          headerName: 'Created on',
          type:'date'
        },
        {
          field: 'updated_at',
          headerName: 'Last edited on',
          type:'date'
        },
        {
          field: 'status',
          headerName: 'Status',
          type: 'status'
        },
        {
          field: 'action',
          headerName: 'Action',
          type: 'action'
        },
      ];

      const actions = [
        {
          label: 'Generate report',
          action: (row:any)=>{
            console.log(row);
            if(row.process === 'normal'){
              toast.warn('Opps! Standard report not available yet');
              return;
            }
            // navigate(`/reports`,{state:{reportResponse:row}})
            createReportMutation(row.id);
          }
        },
        {
          label: 'Edit Request details',
          action: (row:any)=>{
            navigate(`/edit-request/${row.id}`)
          }
        },
        {
          label: 'MOVE TO TRASH',
          action:(row:any)=>{
            // console.log('ondelete');
            if(onDelete) onDelete(row);
          }
        }
      ];

      const clickableFields = ['author.name','customer_name','assets_count'];

      const handleRowClick = (row:any) => {
        console.log(data);
        if(clickableFields.includes(row.field)){
          storeSelectedRequest(row.data);
          navigate(`/requests/${row.data.id}/assets`,{state:{request:row.data}});
        }
      }

      return (
        <APTable
          columns={columns} 
          data={data}
          actions={actions} 
          clickableFields={clickableFields}
          rowPerPage={rowPerPage}
          isLoading={isLoading}
          onRowClick={handleRowClick}
          onRowSelection={onRowSelection}
          onPageChange={onPageChange}
          showCheckbox
        />
      )
}

export default RequestTable;