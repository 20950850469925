import { Avatar, Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { IComment } from "../assets/assetsInterface";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

interface Props {
  comment: IComment;
}

const CommentItem: React.FC<Props> = ({ comment }) => {
  const createdAt = dayjs(comment.created_at);
  const isToday = createdAt.isSame(dayjs(), "day");
  
  const displayDate = isToday 
    ? createdAt.format("hh:mm A") // 12-hour time format
    : createdAt.format("DD/MM/YYYY hh:mm A"); // Date and time if not today

  return (
    <Box sx={{ display: "flex", width: "100%", gap: "8px" }}>
      <Box sx={{ width: "40px", height: "40px" }}>
        <Avatar src={comment.user?.id} />
      </Box>
      <Box>
        <Box sx={{ display: "flex", gap: "4px" }}>
          <Typography sx={{ fontSize: "14px", color: theme => theme.palette.black, fontWeight: 500 }}>
            {comment.user?.name}
          </Typography>
          <Typography sx={{ fontSize: "14px", color: "#86888A" }}>
            {displayDate}
          </Typography>
        </Box>
        {comment.is_range ? (
          <Typography sx={{ fontSize: "14px", color: theme => theme.palette.black }}>
            This is the range <span style={{ color: "#9747FF", fontWeight: 600 }}>USD {comment.content}</span>
          </Typography>
        ) : (
          <Typography sx={{ fontSize: "14px", color: theme => theme.palette.black }}>
            {comment.content}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CommentItem;
