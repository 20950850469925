import * as React from 'react';
import { Box, TextField, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { Control, Controller } from 'react-hook-form';

interface StyledTextareaProps {
  background?: 'filled' | 'transparent';
  size?: 'small' | 'medium';
}

const StyledTextarea = styled((props: StyledTextareaProps & React.ComponentProps<typeof TextField>) => (
  <TextField {...props} multiline rows={2} />
))(({ background, size }) => {
  return {
    '& .MuiInputBase-root': {
      backgroundColor: background === 'transparent' ? '#fff' : '#CED4DA',
      borderRadius: background === 'transparent' ? null : '8px',
      border: background === 'transparent' ? '1px solid #CED4DA' : 'none',
    //   height: size === 'small' ? '42px' : 'auto',
      transition: 'border-color 0.3s ease',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  };
});

interface TextareaProps {
  id?: string;
  label?: string;
  name: string;
  control: Control<any>;
  defaultValue?: string;
  size?: 'small' | 'medium';
  background?: 'filled' | 'transparent';
  disabled?: boolean;
  rules?: object;
}

const TextareaInput: React.FC<TextareaProps> = ({
  id, label, name, size = 'small', background = 'transparent', disabled=false, control, defaultValue = '', rules
}) => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  return (
    <Box sx={{ mb: 2, width: '100%' }}>
      {label && <Typography component="label" htmlFor={id} variant="body1" sx={{ mb: 1, fontSize: '12px', color: '#4F4F4F' }}>
        {label}
      </Typography>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState }) => (
          <StyledTextarea
            {...field}
            id={id}
            variant="outlined"
            disabled={disabled}
            size={size}
            fullWidth
            background={background}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : ''}
            sx={{
              '& .Mui-focused': {
                borderColor: primaryColor,
                borderWidth: '1px',
              },
            }}
          />
        )}
      />
    </Box>
  );
};

export default TextareaInput;
