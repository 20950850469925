import { Box } from "@mui/material";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import CustomAppBar from "../components/appbar/AppBar";
import AppNavigation from "../components/navigation/AppNavigation";
import MainContent from "../components/MainContent";
import DrawerHeder from "../components/appbar/DrawerHeader";
import { APLoader } from "../components/loaders";
import { useGeneralStore } from "../generaStore";
import useInactivityLogout from "../hooks/useInactivityLogout";
import LogoutWarningModal from "../components/dialogs/LogoutWarningModal";

const AppLayout = () => {
  const [open, setOpen] = useState(true);
  const isPageTransition = useGeneralStore(state => state.isPageTransition);
  const { showWarning, resetTimer } = useInactivityLogout(600000, 300000);
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    if (isPageTransition) return;
    setOpen(false);
  };

  return (
    <Box sx={{ width: '100%', height: '100vh' }}>
      {isPageTransition && <APLoader />}
      <CustomAppBar open={open} handleDrawerOpen={handleDrawerOpen} />
      <AppNavigation open={open} handleDrawerClose={handleDrawerClose} />
      <MainContent open={open}>
        <DrawerHeder />
        <Outlet />
        <LogoutWarningModal
          open={showWarning}                 // Control whether the modal is open or closed
          onClose={resetTimer}              // Function to call when the modal is closed
          onStayLoggedIn={resetTimer}       // Function to call when user chooses to stay logged in
        />
      </MainContent>
    </Box>
  );
};

export default AppLayout;
