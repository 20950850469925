import { addDays } from 'date-fns';
import { DateRangePicker } from 'rsuite';

interface Props {
  onDateChange: (date: any) => void;
}

const predefinedRanges:any = [
  {
    label: 'Today',
    value: [new Date(), new Date()],
    placement: 'left'
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left'
  },
  {
    label: 'Last 7 Days',
    value: [addDays(new Date(), -7), new Date()],
    placement: 'left'
  },
  {
    label: 'Last 30 Days',
    value: [addDays(new Date(), -30), new Date()],
    placement: 'left'
  }
];

const APDatePicker: React.FC<Props> = ({ onDateChange }) => {
  const { afterToday } = DateRangePicker;

  return (
    <DateRangePicker
      placeholder="Select Date Range"
      placement="bottomEnd"
      format="MM/dd/yyyy"
      character=" - "
      showOneCalendar
      onChange={(val) => onDateChange(val)}
      showMeridiem
      shouldDisableDate={afterToday()}
      ranges={predefinedRanges}
    />
  );
};

export default APDatePicker;
