import { Toolbar, Box, Typography, styled, BadgeProps, Avatar, Badge, IconButton } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { DRAWER_WIDTH } from "../../Constant";
import { NotificationsNoneOutlined, MenuOutlined } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "../../features/auth/authStore";
import { url } from "inspector";

interface Props {
    open: boolean;
    handleDrawerOpen: () => void;
}

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        marginLeft: `${DRAWER_WIDTH}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: -2,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const CustomAppBar: React.FC<Props> = ({ open, handleDrawerOpen }) => {
    const location = useLocation();
    const authUser = useAuthStore(state => state.authUser);
    const navigate = useNavigate();

    // Array of page titles and their associated URLs
    const pageTitles = [
        { pageTitle: 'Request', urls: ['/requests','/new-request'] },
        { pageTitle: 'Assets', urls: ['/assets','/new-asset',/^\/requests\/[A-Za-z0-9]+\/assets$/] },
        { pageTitle: 'Edit request', urls: [/^\/edit-request\/[A-Za-z0-9]+$/] },
        { pageTitle: 'Assets details', urls: [/^\/edit-asset\/[A-Za-z0-9]+$/] },
        { pageTitle: 'Compare', urls: ['/compare'] },
        { pageTitle: 'Organization management', urls: ['/organization-management'] },
        { pageTitle: 'User management', urls: ['/user-management'] },
        { pageTitle: 'Reports', urls: ['/reports']},
        { pageTitle: 'Report preview', urls: ['/uspap-preview']},
        { pageTitle: 'Profile', urls: ['/profile']}
    ];

    // Function to get the page title based on the current pathname
    const getPageTitle = (path: string) => {
        // console.log(path);
        for (const entry of pageTitles) {
            if (entry.urls.some(url => {
                if (typeof url === 'string') {
                    return path === url;
                } else if (url instanceof RegExp) {
                    return url.test(path); // Test against regex
                }
                return false;
            })) {
                return entry.pageTitle;
            }
        }
        return 'Dashboard'; // Default title if no match is found
    };
    

    const pageTitle = getPageTitle(location.pathname);

    return (
        <AppBar
            position="fixed"
            elevation={0}
            open={open}
            sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.divider_color}`,
                backgroundColor: (theme) => theme.palette.white,
                color: (theme) => theme.palette.black,
            }}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{ mr: 2, ...(open && { display: 'none' }) }}
                >
                    <MenuOutlined />
                </IconButton>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Typography sx={{ fontSize: '32px', fontWeight: '500', color: (theme) => `${theme.palette.black}` }}>
                        {pageTitle}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 4, ":hover": { cursor: 'pointer' } }}>
                        {pageTitle !== 'Profile' && (
                            <Box onClick={() => navigate('/profile')} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Avatar alt={authUser?.name} src={authUser?.avatar} />
                                <Typography sx={{ fontSize: '13px' }}>{authUser?.name}</Typography>
                            </Box>
                        )}
                        {/* <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"
                            sx={{
                                backgroundColor: (theme) => `${theme.palette.black}15`,
                                width: '40px',
                                height: '40px',
                                borderRadius: '100%',
                            }}
                        >
                            <StyledBadge badgeContent={0} color="error">
                                <NotificationsNoneOutlined sx={{ fontSize: '22px' }} />
                            </StyledBadge>
                        </IconButton> */}
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default CustomAppBar;
