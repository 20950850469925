import Box from "@mui/material/Box";
import APDivider from "../../components/APDivider";
import { RichTextInput } from "../../components/inputs";
import { Control } from "react-hook-form";

interface Props{
    control: Control<any>;
}

const WorkScope:React.FC<Props> = ({control}) => {
    return (
        <Box>
            <APDivider text="Scope of work" margin="40px 0px"/>
            <RichTextInput id="work_scope" name="work_scope" control={control} />
        </Box>
    )
}
export default WorkScope;