export const DASHBOARD_CARD_DATA = [
    {
        "status": "new",
        "total_requests": 0,
        "total_assets": 0
    },
    {
        "status": "in_progress",
        "total_requests": 0,
        "total_assets": 0
    },
    {
        "status": "awaiting_feedback",
        "total_requests": 0,
        "total_assets": 0
    },
    {
        "status": "completed",
        "total_requests": 0,
        "total_assets": 0
    }
]