import React from 'react';
import { Radio, RadioGroup, FormControlLabel, styled, useTheme, Box, Typography } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

interface StyledRadioProps {
  size?: 'small' | 'medium';
}

const StyledRadio = styled((props: StyledRadioProps & React.ComponentProps<typeof Radio>) => (
  <Radio {...props} />
))(({ size }) => ({
  color: '#4F4F4F',
  '&.Mui-checked': {
    color: '#007BFF',
  },
  '& .MuiSvgIcon-root': {
    fontSize: size === 'small' ? '1.2rem' : '1.5rem',
  },
}));

interface LabeledRadioProps {
  id?: string;
  label?: string;
  name: string;
  control: Control<any>;
  size?: 'small' | 'medium';
  defaultValue?: string;
  rules?: object;
  options: Array<{ label: string; value: string }>;
  layout?: 'row' | 'column';
}

const RadioInput: React.FC<LabeledRadioProps> = ({
  id, label, name, size = 'medium', control, defaultValue = '', rules, options, layout = 'row'
}) => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  return (
    <Box sx={{ mb: 2, width: '100%' }}>
      {label && (
        <Typography component="label" htmlFor={id} variant="body1" sx={{ mb: 1, fontSize: '12px', color: '#4F4F4F' }}>
          {label}
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: layout === 'row' ? 'row' : 'column',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={rules}
          render={({ field, fieldState }) => {
            return (
              <RadioGroup
                row={layout === 'row'}
                value={field.value || defaultValue}
                onChange={(e) => field.onChange(e.target.value)}
              >
                {options.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={
                      <StyledRadio
                        size={size}
                        id={option.value}
                        sx={{
                          color: fieldState.error ? 'red' : primaryColor,
                          '&.Mui-checked': {
                            color: primaryColor,
                          },
                        }}
                      />
                    }
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default RadioInput;
