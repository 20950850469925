import * as React from 'react';
import Box from '@mui/material/Box';
import Select, { MultiValueGenericProps, MultiValueRemoveProps, StylesConfig, components} from 'react-select';
import { Typography } from '@mui/material';
import { getAbbreviation } from '../../utils/helpers';
import { MaterialSymbol } from 'react-material-symbols';
import { Control, Controller } from 'react-hook-form';

const styles: StylesConfig<unknown,true> = {
  control: (styles) => ({ ...styles, minHeight:'42px' }),
  multiValue: (base) => ({
    ...base,
    borderRadius:'100px',
    backgroundColor:`#00000010`,
  }),
}

const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
      <components.MultiValueRemove {...props}>
        <Box sx={{borderRadius:'100%', backgroundColor:'#BDBDBD', display:'flex',alignItems:'center',justifyContent:'center'}}>
          <MaterialSymbol icon='close' size={16} color='#ffffff'/>
        </Box>
      </components.MultiValueRemove>
  );
};

const MultiValueLabel = (props: MultiValueGenericProps) => {
  const abbreviation = getAbbreviation(props.data.label);

  return (
    <components.MultiValueLabel {...props}>
      <Box sx={{display:'flex', alignItems:'center', gap:'6px'}}>
        <Box sx={{ 
            backgroundColor:'#BDBDBD',
            borderRadius:'100%',  
            color:'#ffffff', 
            width:'18px', 
            height:'18px', 
            display:'flex', 
            alignItems:'center', 
            justifyContent:'center',
          }}>
          <Typography sx={{fontSize:'10px', fontWeight:'500' }}>
            {abbreviation}
          </Typography>
        </Box>
        <Typography sx={{fontSize:'13px'}}>
          {props.data.label}
        </Typography>
      </Box>
    </components.MultiValueLabel>
  );
};

interface Props{
  id?: string;
  label?: string;
  name: string;
  control: Control<any>
  defaultValue?: string;
  isMultiple?: boolean;
  rules?: object;
  options: any[];
  isDisabled?:boolean;
}

const SelectInput:React.FC<Props> = ({id, label, name, control, defaultValue='', isMultiple, options, rules, isDisabled}) => {
  return (
    <Box sx={{ mb:2, width:'100%'}}>
      {label && <Typography sx={{ mb: 1, fontSize:'12px', color:'#4F4F4F' }}>
        {label}
      </Typography>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState }) => (
          <Box>
            <Select
              {...field}
              id={id}
              components={{MultiValueLabel, MultiValueRemove }}
              options={options}
              styles={styles}
              isMulti={isMultiple}
              isDisabled={isDisabled}
            />
            {fieldState.error && (
              <Typography variant="body2" color="error" sx={{ mt: '4px',mx:'14px', fontSize:'12px' }}>
                {fieldState.error.message}
              </Typography>
            )}
          </Box>
        )}
      />
    </Box>
  );
}

export default SelectInput;
