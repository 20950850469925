import React from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { DesignSystemPage, NotFoundPage } from '../pages';
import AuthLayout from '../features/auth/AuthLayout';
import ForgotPasswordForm from '../features/auth/forgotPasswordForm';
import LoginForm from '../features/auth/loginForm';
import ResetPasswordForm from '../features/auth/resetPasswordForm';
import OTPForm from '../features/auth/otpForm';
import AppLayout from '../pages/AppLayout';
import DashboardPage from '../features/dashboard/DashboardPage';
import RequestsPage from '../features/requests/RequestsPage';
import RequestForm from '../features/requests/request-form/RequestForm';
import RequestEditForm from '../features/requests/request-form/EditRequestForm';
import AssetsPage from '../features/assets/AssetsPage';
import ImageUploader from '../dev/BulkFileUploadPoc';
import UserManagementPage from '../features/user-management/UserManagementPage';
import AssetComparePage from '../features/asset-compare/AssetComparePage';
import NotAuthorizedPage from '../pages/NotAuthorizedPage';
import AddAssetForm from '../features/assets/AddAssetForm';
import ProfilePage from '../features/profile/ProfilePage';
import EditAssetForm from '../features/assets/EditAssetForm';
import OrganizationManagementPage from '../features/organization-manageent/OrganizationManagementPage';
import ReportsPage from '../features/reports/ReportsPage';
import UspapReportPreviewPage from '../features/reports/preview/UspapReportPreviewPage';

const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    children: [
      {
        path: "/login",
        element: localStorage.getItem('authToken') ? <Navigate to="/" /> : <LoginForm />
      },
      { path: '/forgot-password', element: <ForgotPasswordForm /> },
      { path: '/reset-password/*', element: <ResetPasswordForm /> },
      { path: '/set-password/*', element: <ResetPasswordForm /> },
      { path: '/confirm-email', element: <OTPForm /> },
    ]
  },
  {
    element: <ProtectedRoute allowedRoles={['super_admin', 'admin', 'appraiser', 'user', 'guest']} />,
    children: [
      {
        element: <AppLayout />,
        children: [
          { path: '/', element: <DashboardPage /> },
          { path: '/requests', element: <RequestsPage /> },
          { path: '/requests/:requestId/assets', element: <AssetsPage /> },
          { path: '/new-asset', element: <AddAssetForm /> },
          { path: '/edit-asset/:id', element: <EditAssetForm /> },
          { path: '/new-asset/:id', element: <AddAssetForm /> },
          { path: '/compare/:id', element: <AssetComparePage /> },
          { path: '/new-request', element: <RequestForm /> },
          { path: '/edit-request/:id', element: <RequestEditForm /> },
          { path: '/profile', element: <ProfilePage /> },
          { path: '/reports', element:<ReportsPage /> },
          // { path: '/uspap-preview', element:<UspapReportPreviewPage /> }
        ]
      }
    ]
  },
  {
    element: <ProtectedRoute allowedRoles={['super_admin', 'admin']} />,
    children: [
      {
        element: <AppLayout />,
        children: [
          { path: '/user-management', element: <UserManagementPage /> },
        ]
      }
    ]
  },
  {
    element: <ProtectedRoute allowedRoles={['super_admin']} />,
    children: [
      {
        element: <AppLayout />,
        children: [
          { path: '/organization-management', element: <OrganizationManagementPage /> },
        ]
      }
    ]
  },
  {
    element: <ProtectedRoute allowedRoles={['super_admin']} />,
    children: [
      { path: "/dev/design-system", element: <DesignSystemPage /> },
      { path: "/dev/file-upload-poc", element: <ImageUploader /> },
    ]
  },
  { path: "/not-authorized", element: <NotAuthorizedPage /> },
  { path: "*", element: <NotFoundPage /> },
]);

const AppRoutes: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default AppRoutes;
