import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../features/auth/authStore';

const useInactivityLogout = (timeout: number, warningTime: number) => {
  const navigate = useNavigate();
  const clearLogin = useAuthStore((state) => state.clearLogin);
  const lastActivityTimeRef = useRef(Date.now()); // Use ref to store last activity time
  const [showWarning, setShowWarning] = useState(false);

  const resetTimer = () => {
    lastActivityTimeRef.current = Date.now(); // Update ref without triggering re-render
    setShowWarning(false);
  };

  useEffect(() => {
    const handleUserActivity = () => resetTimer();
    const events = ['click', 'keypress', 'scroll', 'touchstart'];

    events.forEach((event) => window.addEventListener(event, handleUserActivity));

    const interval = setInterval(() => {
      const timeSinceLastActivity = Date.now() - lastActivityTimeRef.current;

      if (timeSinceLastActivity > warningTime && timeSinceLastActivity < timeout) {
        setShowWarning(true);
      }

      if (timeSinceLastActivity > timeout) {
        clearLogin();
        navigate('/login');
      }
    }, 1000);

    return () => {
      events.forEach((event) => window.removeEventListener(event, handleUserActivity));
      clearInterval(interval);
    };
  }, [timeout, warningTime, clearLogin, navigate]);

  return { resetTimer, showWarning };
};

export default useInactivityLogout;
