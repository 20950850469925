import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthStore } from '../features/auth/authStore';

interface ProtectedRouteProps {
  allowedRoles: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ allowedRoles }) => {
  const { authUser, isLoggedIn } = useAuthStore();
  const location = useLocation();

  // Check if the user is logged in
  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // Check if the user's role is in the allowed roles
  const hasAccess = authUser ? allowedRoles.includes(authUser.role) : false;

  // Redirect to "not authorized" page if access is denied
  if (!hasAccess) {
    return <Navigate to="/not-authorized" />;
  }

  // Render the child routes
  return <Outlet />;
};

export default ProtectedRoute;
