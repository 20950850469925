import { Box, Typography } from "@mui/material";
import IconText from "../asset-compare/IconText";

interface Props{
    asset?:any;
}

const AssetSummary:React.FC<Props> = ({asset}) => {
    // console.log('asset',asset)
    const {make,model,year,vin,description,location, meters} = asset;
    const newMeters = (typeof meters === 'string' ? JSON.parse(meters) : meters);
    const miles = newMeters.find((meter: any) => meter.units === 'Miles')?.value;

    return(
        <Box>
            <Typography sx={{fontWeight:'bold'}}>Summary</Typography>
            <Box sx={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
                <Typography sx={{fontSize:'14px'}}>{make} {model} {year}</Typography>
                <Box sx={{display:'flex', alignItems:'center',gap:3}}>
                    <IconText text={vin} icon={'directions_car'}/>
                    <IconText text={miles} icon={'speed'} />
                    {/* <IconText text={asset?.description} icon={'settings'}/> */}
                    <IconText text={location} icon={'location_on'}/>
                </Box>
            </Box>
        </Box>
    )
}

export default AssetSummary;