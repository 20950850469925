import { Box } from '@mui/material'
import React from 'react'
import APDivider from '../../components/APDivider'
import { Control } from 'react-hook-form';
import { RichTextInput } from '../../components/inputs';

interface Props{
  control: Control<any>;
}
const Sources:React.FC<Props> = ({control}) => {
  return (
    <Box>
      <APDivider text='Sources' margin="40px 0px"/>
      <RichTextInput id="sources" name="sources" control={control} />
    </Box>
  )
}

export default Sources