// dataService.ts
export interface CategoryOption {
    id: number;
    value: number;
    label: string;
    family: string;
    industry: string;
  }
  
  export const fetchAndTransformData = async (): Promise<CategoryOption[]> => {
    const response = await fetch('https://ims-api.purplewave.com/v1/industries?published=1');
    const data = await response.json();
  
    // Transformation logic
    const transformedData: CategoryOption[] = [];
    
    data.forEach((industryItem: any) => {
      const industry = industryItem.industry;
  
      industryItem.Families.forEach((familyItem: any) => {
        const family = familyItem.family;
  
        familyItem.Categories.forEach((categoryItem: any) => {
          transformedData.push({
            id: categoryItem.id,
            value: categoryItem.id, // category ID
            label: categoryItem.category, // category name
            family: family, // associated family name
            industry: industry // associated industry name
          });
        });
      });
    });
  
    return transformedData;
  };
  