import { appAPI } from "../../httpClient";
import { RequestFormValues } from "./requestInterfaces";

export const getRequests = async(params?:any) =>{
   const res = await appAPI.get('/requests',{params});
   return {
    meta: res.data.meta,
    data: res.data.data
   }
};
export const getRequest = async(requestId:string) => {
   const res = await appAPI.get(`/requests/${requestId}`);
   return res.data;
}
export const postRequest = async(formValues:RequestFormValues) => appAPI.post('/requests',formValues);
export const updateRequest = async(requestId:string,formValues:RequestFormValues) => appAPI.patch(`/requests/${requestId}`,formValues);
export const deleteRequest = async(requestId:string) => appAPI.delete(`/requests/${requestId}`);
export const bulkDeleteRequest = async(payload:any) => appAPI.post(`/requests/bulk_delete`,payload);