import { Box } from "@mui/material";
import Introduction from "./Introduction";
import IntendedUse from "./IntendedUse";
import RestrictedType from "./RestrictedType";
import WorkScope from "./WorkScope";
import ApproachesToValue from "./ApproachesToValue";
import MarketConditionAnalyze from "./MarketConditionAnalyze";
import Sources from "./Sources";
import ExtraodinaryAssumptions from "./ExtraodinaryAssumptions";
import HypotheticalConditions from "./HypotheticalConditions";
import LimitingConditions from "./LimitingConditions";
import { useForm } from "react-hook-form";
import { APIconButton, DefaultButton } from "../../components/buttons";
import { MaterialSymbol } from "react-material-symbols";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { generateReport, updateReport } from "./reportQueries";
import { toast } from "react-toastify";
import { handleError } from "../../utils/errorHandler";
import { useEffect, useRef, useState } from "react";
import UspapReportPreviewPage from "./preview/UspapReportPreviewPage";
import { IReportForm } from "./reportInterface";
import html2pdf from 'html2pdf.js';

/*
const reportResponse:any = {
    "id": "01JCG0TM74MWKG5WV4CC331S9E",
    "report_type": "normal",
    "introduction": "Introduction for the Report",
    "intended_use": "For the intended use only.",
    "intended_user": "testa@email.com",
    "report_date": "2024-11-12T11:06:03.851Z",
    "inspection_date": null,
    "work_scope": "Updated scope again",
    "restricted": false,
    "sources": "Updated",
    "market_conditions": "",
    "assumptions": "",
    "hypothetical_conditions": "",
    "limiting_conditions": "",
    "request": {
      "id": "01JCFY2ZACWQJB8RS7VG96TJG5",
      "valuation_reasons": [
        "Financing",
        "Liquidation"
      ],
      "estimate_options": [
        "olv",
        "flv"
      ],
      "notes": "Some notes about the request",
      "status": "new",
      "process": "normal",
      "capture_mode": "self",
      "created_at": "2024-11-12T10:18:11.652Z",
      "updated_at": "2024-11-12T10:18:11.652Z",
      "organization": {
        "id": "01JCFX4YYGNNCGP55AYNK5W61Z",
        "name": "Purple Wave Inc. (PWAS)",
        "email": "info@pwvaluation.com",
        "active": true
      },
      "manager": {
        "id": "01JCFX4YQ3ZAKWVK49NZ5G8MK2",
        "name": "PWAS Appraiser",
        "email": "appraiser@pwvaluation.com"
      },
      "customer_name": "Test Owner",
      "customer_email": "test@email.com",
      "assets_count": 0,
      "media_count": 0
    },
    "created_at": "2024-11-12T11:06:03.850Z",
    "updated_at": "2024-11-12T11:50:21.599Z"
  }
    */
const ReportsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const pdfRef = useRef(null);
    const reportResponse = location.state.reportResponse;
    const [showPreview, setShowPreview] = useState(false);


    // console.log(reportResponse);
    const { control, handleSubmit, reset, getValues } = useForm();

    const { isPending: isLoading, mutate: postReportMutation } = useMutation({
        mutationKey: ['postReportMutation'],
        mutationFn: ({ formValues }: {formValues:any}) =>
            updateReport(reportResponse.request.id,formValues),
        onSuccess: (res,variables) => {
          console.log(variables);
          if(res.status === 204){
            toast.error('An error occured during request creation');
          }
          else{
            toast.success('Report saved successfully');
          }
        },
        onError: handleError,
      });

      const { isPending: isGenerating, mutate: generateReportMutation } = useMutation({
        mutationKey: ['generateReportMutation'],
        mutationFn: ({ pdfBlob }: {pdfBlob:Blob}) =>
            generateReport(reportResponse.request.id,pdfBlob),
        onSuccess: (res,variables) => {
          console.log(variables);
          if(res.status === 204){
            toast.error('An error occured during request creation');
          }
          else{
            toast.success('Report generated successfully. Kindly check your email.');
          }
        },
        onError: handleError,
      });

    const handleReportReview = () => {
        setShowPreview(true);
        // console.log(reportResponse);
        // console.log(getValues());
        /*
        navigate('/uspap-preview',{state:{
            reportResponse,
            report: getValues()
            // report: {
            //     "introduction": "<p>Lorem Ipsum&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>",
            //     "intended_use": "<p>Lorem Ipsum&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>",
            //     "intended_user": "Some user",
            //     "effective_date": "23-10-2020",
            //     "report_date": "23-2-2302",
            //     "inspection_date": "12-03-2323",
            //     "restriction_type": "false",
            //     "work_scope": "<p>Lorem Ipsum&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p><p><br></p>",
            //     "approaches_to_value": [
            //         "income",
            //         "cost"
            //     ],
            //     "market_condition_analyzed": "<p>Lorem Ipsum&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>",
            //     "sources": "<p>asdfsdfsdfLorem Ipsum&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>",
            //     "assumptions": "<p>Lorem Ipsum&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>",
            //     "hypothetical_contitions": "<p>Lorem Ipsum&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>",
            //     "limiting_conditions": "<p>Lorem Ipsum&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>",
            //     "appraiser_qualifications": "<p>Lorem Ipsum&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>",
            //     "file": null
            // }
        }});
        */
    }

    /*
    const handleGeneratePdf = () => {
        console.log('handle generate clicked');
        const element = pdfRef.current;
        console.log('current ref: ',pdfRef.current)
        const options = {
          margin: 1,
          filename: 'document.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
    
        html2pdf()
          .from(element)
          .set(options)
          .save();
    };
    */
    const handleGeneratePdf = async () => {
        const element = pdfRef.current;
        if (!element) {
          toast.error('PDF element not found!');
          return;
        }
    
        const options = {
          margin: 1,
          filename: 'document.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };
    
        try {
          // Generate PDF as Blob
          const pdfBlob = await html2pdf().from(element).set(options).outputPdf('blob');
    
          // Trigger mutation with form values and PDF Blob
          generateReportMutation({ pdfBlob });
        } catch (error) {
          console.error('Error generating PDF:', error);
          toast.error('Failed to generate PDF.');
        }
      };

    const handleBackClick = () => {
        setShowPreview(false);
    }

    // const handleGenerateReport = () =>{
        
    // }

    useEffect(()=>{
        const data = {...reportResponse, restricted:reportResponse.restricted?'true':'false'};
        console.log('data: ',data);
        reset({...data})
    },[reportResponse, reset])
    return(
        <Box sx={{pl:'11px',pt:'16px',pr:'30px'}}>
            {!showPreview && <form>
                <Introduction control={control} customer_name={reportResponse.request.customer_name}/>
                <IntendedUse control={control}/>
                <RestrictedType control={control}/>
                <WorkScope control={control}/>
                <ApproachesToValue control={control}/>
                <MarketConditionAnalyze control={control}/>
                <Sources control={control}/>
                <ExtraodinaryAssumptions control={control}/>
                <HypotheticalConditions control={control}/>
                <LimitingConditions control={control}/>
                {/* <AppraiserQualifications control={control}/>
                <Box sx={{backgroundColor:'#fff', my:'40px', height:'120px'}}>
                    <FileInput
                        label="Drag and drop logo"
                        name="file"
                        control={control}
                        type="image"
                    />
                </Box> */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems:'center', marginTop: '20px' }}>
                    <Box sx={{display:'flex', alignItems:'center', width:'25%', gap:3}}>
                    <DefaultButton 
                        text='Download report'
                        onClick={handleGeneratePdf}
                        isLoading={isGenerating||isLoading}
                    />
                    <DefaultButton 
                        text='Preview report'
                        onClick={handleReportReview}
                        variant='outlined'
                        // isLoading={isUpdating}
                    />
                    </Box>
                    <Box sx={{display:'flex', alignItems:'center', gap:3}}>
                    <APIconButton
                        text='Cancel'
                        isRounded={false}
                        color={'secondary'}
                        icon={<MaterialSymbol icon='delete' />}
                        isDisabled={isLoading||isGenerating}
                        onClick={() => navigate(-1)}
                    />
                    <APIconButton
                        text='Save'
                        isRounded={false}
                        variant='text'
                        icon={<MaterialSymbol icon='arrow_forward_ios' />}
                        isDisabled={isLoading || isGenerating}
                        onClick={() => { postReportMutation({formValues:getValues()})}}
                    />
                    </Box>
                </Box>
            </form>}
            {
                    <div style={{ display: showPreview ? 'block' : 'none' }}>
                      <UspapReportPreviewPage
                        pdfRef={pdfRef}
                        reportResponse={reportResponse}
                        report={getValues() as IReportForm}
                        onBackClick={handleBackClick}
                      />
                    </div>
                
            }
        </Box>
    )
}

export default ReportsPage;