import { Box, Button, CircularProgress, Grid, Modal, Typography, IconButton } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { MaterialSymbol } from "react-material-symbols";
import { IImage } from "./assetsInterface";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface Props {
  onOpen: boolean;
  isProcessing?: boolean;
  images?: IImage[];
  showDelete?: boolean;
  onClose?: () => void;
  onDelete?: (index: number) => void;
  onAttach?: () => void;
}

const ImagePreviewModal: React.FC<Props> = ({
  onOpen,
  images = [],
  isProcessing,
  showDelete,
  onClose,
  onDelete,
  onAttach,
}) => {
  const imagesPerPage = 8;
  const [currentIndex, setCurrentIndex] = useState(1);
  const [mainImage, setMainImage] = useState<string>(images[0]?.url);
  const [currentPage, setCurrentPage] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1); // Zoom state
  const [isDragging, setIsDragging] = useState(false); // Dragging state
  const [position, setPosition] = useState({ x: 0, y: 0 }); // Image position
  const [lastMousePosition, setLastMousePosition] = useState({ x: 0, y: 0 }); // Last mouse position for dragging

  const imageContainerRef = useRef<HTMLDivElement | null>(null); // Ref to the image container

  const totalImages = images.length;

  useEffect(() => {
    if (!isProcessing && images.length > 0) {
      setMainImage(images[0].url);
    }
  }, [images, isProcessing]);

  // Function to handle zoom in
  const handleZoomIn = () => {
    setZoomLevel((prev) => Math.min(prev + 0.2, 3)); // Max zoom level
  };

  // Function to handle zoom out
  const handleZoomOut = () => {
    setZoomLevel((prev) => Math.max(prev - 0.2, 1)); // Min zoom level
    setPosition({ x: 0, y: 0 }); // Reset position when zooming out
  };

  // Handle mouse down event to start dragging
  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (zoomLevel > 1) {
      setIsDragging(true);
      setLastMousePosition({ x: event.clientX, y: event.clientY });
    }
  };

  // Handle mouse up event to stop dragging
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Handle mouse movement to update image position
  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isDragging) {
      const dx = event.clientX - lastMousePosition.x;
      const dy = event.clientY - lastMousePosition.y;
      setPosition((prev) => ({
        x: prev.x + dx,
        y: prev.y + dy,
      }));
      setLastMousePosition({ x: event.clientX, y: event.clientY });
    }
  };

  return (
    <Modal
      open={onOpen}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} // Center modal
    >
      <Box
        sx={{
          width: "50%",
          backgroundColor: "white",
          borderRadius: "8px",
          height: "70%",
          overflow: "hidden",
        }}
      >
        {isProcessing && (
          <Box sx={{ width: "100%", textAlign: "center", mt: 2 }}>
            <CircularProgress />
            <Typography variant="body2" color="textSecondary">
              Processing images...
            </Typography>
          </Box>
        )}
        {!isProcessing && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {/* Main Image Preview with Zoom and Panning */}
            <Box
              ref={imageContainerRef}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
              sx={{
                width: "70%",
                height: "85%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative", // Required for absolute positioning of zoom controls
                overflow: "hidden",
                cursor: zoomLevel > 1 ? "grab" : "default", // Change cursor when zooming
              }}
            >
              <img
                src={mainImage!}
                alt="Main Display"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`, // Apply zoom level and translation for panning
                  transition: isDragging ? "none" : "transform 0.3s ease", // Smooth transition unless dragging
                }}
                onMouseLeave={handleMouseUp} // Stop dragging if the mouse leaves the image area
              />

              {/* Zoom Controls */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: 20,
                  right: 20,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <IconButton
                  onClick={handleZoomIn}
                  sx={{
                    backgroundColor: "white",
                    "&:hover": { backgroundColor: "lightgray" },
                    mb: 1,
                  }}
                  disabled={zoomLevel >= 3}
                >
                  <AddIcon />
                </IconButton>
                <IconButton
                  onClick={handleZoomOut}
                  sx={{
                    backgroundColor: "white",
                    "&:hover": { backgroundColor: "lightgray" },
                  }}
                  disabled={zoomLevel <= 1}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            </Box>

            <Box
              sx={{
                width: "30%",
                height: "100%",
                p: "8px 10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                  }}
                >
                  <Typography>{images.length} images</Typography>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Box onClick={onAttach} sx={{ cursor: "pointer" }}>
                      <MaterialSymbol icon="check" size={20} />
                    </Box>
                    <Box onClick={onClose} sx={{ cursor: "pointer" }}>
                      <MaterialSymbol icon="close" size={20} />
                    </Box>
                  </Box>
                </Box>

                <Grid container rowSpacing={1} columnSpacing={-0.5} sx={{ my: 2 }}>
                  {images
                    .slice(currentPage * imagesPerPage, (currentPage + 1) * imagesPerPage)
                    .map((image, index) => (
                      <Grid item xs={6} key={image.filename}>
                        <Box sx={{ position: "relative" }}>
                          <Button
                            sx={{
                              border:
                                mainImage === image.url
                                  ? "2px solid blue"
                                  : "none",
                              height: "120px",
                              width: "120px",
                              padding: 0,
                              borderRadius: 0,
                            }}
                            onClick={() => {
                              setMainImage(image.url);
                              setCurrentIndex(
                                currentPage > 0
                                  ? index + imagesPerPage * currentPage + 1
                                  : index + 1
                              );
                              setZoomLevel(1); // Reset zoom on new image
                              setPosition({ x: 0, y: 0 }); // Reset position on new image
                            }}
                          >
                            <img
                              src={image.url}
                              alt={`Thumbnail ${index}`}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                            />
                          </Button>
                          {showDelete && (
                            <Box
                              sx={{
                                position: "absolute",
                                top: "10px",
                                right: "20px",
                                cursor: "pointer",
                                backgroundColor: "white",
                                borderRadius: "100%",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              onClick={() => {
                                if (onDelete) onDelete(index);
                              }}
                            >
                              <MaterialSymbol icon="delete" size={16} color="primary" />
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Button
                  disabled={currentPage === 0}
                  onClick={() => setCurrentPage((prev) => prev - 1)}
                >
                  Previous
                </Button>
                <Typography variant="body1" sx={{ mx: 2 }}>
                  {currentIndex}/{images.length}
                </Typography>
                <Button
                  disabled={(currentPage + 1) * imagesPerPage >= totalImages}
                  onClick={() => setCurrentPage((prev) => prev + 1)}
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ImagePreviewModal;
