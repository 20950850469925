import { Box } from "@mui/material"
import { useFetchOrganizations } from "../../hooks/useFetchOrganizations";
import { useCallback, useEffect, useState } from "react";
import { useGeneralStore } from "../../generaStore";
import APTableFilter from "../../components/table/APTableFilter";
import OrganizationForm from "./OrganizationForm";
import { useFetchUsers } from "../../hooks/useFetchUsers";
import { flattenObject } from "../../utils/jsonObjectNormalize";
import OrganizationTable from "./OrganizationTable";
import { IPagination } from "../../interfaces";

const OrganizationManagementPage: React.FC = () => {
    const setPageTransition = useGeneralStore((state) => state.setIsPageTransition);
    const { data: usersRes } = useFetchUsers({limit:50000, 'filters[role]':'admin'});
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [tableData, setTableData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [prevResults, setPrevResults] = useState<any[] | undefined>(undefined);
    const [params, setParams] = useState({limit:10,page:1});
    
    const { isPending: isLoading, isRefetching, data, refetch } = useFetchOrganizations(true,params);
    const handleNewOrganization = () => setOpenModal(true);
  
    const handleCloseForm = (event: any) => {
      setSelectedRow(null);
      setOpenModal(false);
    };

    const handleSearchResult = useCallback((results: any) => {
      console.log('org search',results);
      if (results) {
        setTableData(results.data.map((item: any) => flattenObject(item)));
        setTotalCount(results.meta.total);
      } else if (results === undefined && prevResults && prevResults.length === 0) {
          refetch();
      }
      
      // Update the previous results
      setPrevResults(results.data);
  }, [refetch, prevResults]);

  const handlePageChange = (page:IPagination) => {
    setParams((prev:any)=>({...prev,page:page.page+1}));
  }
  
    useEffect(() => {
      setPageTransition(isLoading);
      if (!isLoading && data?.data) {
        setTableData(data.data.map((item: any) => flattenObject(item)));
        setTotalCount(data?.meta.total);
      }
  
      return () => {
        setPageTransition(false);
        setSelectedRow(null);
      };
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isRefetching]);
  
    return (
      <Box sx={{padding:theme=> theme.spacing(3)}}>
        <Box sx={{mb: '12px'}}>
        <APTableFilter
          showAdd
          showSearch
          addText="New organization"
          searchEndpoint="/organizations"
          addAction={handleNewOrganization}
          onSearchResult={handleSearchResult}
        />
        </Box>
          <OrganizationTable 
            data={{rowData:tableData, rowCount:totalCount}}
            isLoading={isLoading}
            onRefetch={refetch}
            onOpenForm={(row)=>{
              setSelectedRow(row);
              setOpenModal(true);
            }}
            onPageChange={handlePageChange}
          />
        <OrganizationForm
          onClose={handleCloseForm}
          open={openModal}
          users={usersRes?.data}
          selectedRow={selectedRow}
          refetchOrganizations={refetch}
        />
      </Box>
    );
  };

export default OrganizationManagementPage;