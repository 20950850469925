import { Box, Divider, Typography } from "@mui/material";

interface Props{
    text?: string;
    position?: 'start'|'middle'|'end';
    margin?: string;
}

const APDivider:React.FC<Props> = ({text, position='start', margin}) => {

    return (
        <Box sx={{
            display:'flex',
            alignItems:'center',
            gap:1,
            width:'100%',
            margin:margin
        }}>
            { (position === 'end' || position === 'middle')  &&<Divider sx={{flex:1}} />}
            {text && <Typography sx={{fontSize:'16px', fontWeight:'medium'}}>{text}</Typography>}
            {(position === 'start' || position === 'middle') && <Divider sx={{flex:1}} />}
        </Box>
    )
}

export default APDivider;