import { Box, Grid, Modal, Typography } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import { APIconButton } from "../../../components/buttons";
import { MaterialSymbol } from "react-material-symbols";
import { LabelInput } from "../../../components/inputs";
import { isEmail, isRequired } from "../../../utils/validationRules";
import { useForm } from "react-hook-form";
import { IUserForm } from "../../user-management/userInterfaces";

interface Props {
    open: boolean;
    onClose: (event: any) => void;
    onSubmit: (val:IUserForm) => void;
  }
  
  const AddViewerForm: React.FC<Props> = ({ open, onClose, onSubmit }) => {
    const { control, handleSubmit, reset } = useForm<IUserForm>();
  
    const handleOnClose = (event: any) => {
      reset({
        name: '',
        email: ''
      });
      onClose(event);
    };
  
    return (
      <Modal open={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 700,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          <Box sx={{ width: '100%' }}>
            <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  backgroundColor: '#FCFCFC',
                  p: '25px',
                  borderRadius: '8px 8px 0px 0px',
                }}
              >
                <Typography sx={{ fontWeight: 'bold', fontSize: '24px', color: '#000000' }}>
                  Invite viewer
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <Box>
                    <APIconButton
                      text="Cancel"
                      icon={<MaterialSymbol icon="close" />}
                      onClick={handleOnClose}
                      whiteBackground
                    />
                  </Box>
                  <APIconButton
                    text={'Send invite'}
                    icon={<MaterialSymbol icon="east" />}
                    type="submit"
                  />
                </Box>
              </Box>
              <Box sx={{ width: '100%', p: '25px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <LabelInput
                      id="user-fullname"
                      label="Enter full name"
                      name="name"
                      control={control}
                      rules={{ validate: { isRequired } }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LabelInput
                      id="user-email"
                      label="Enter email"
                      name="email"
                      control={control}
                      rules={{ validate: { isRequired, isEmail } }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </ValidatorForm>
          </Box>
        </Box>
      </Modal>
    );
  };
  
  export default AddViewerForm;