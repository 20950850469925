import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import * as XLSX from 'xlsx';
import { Control, Controller } from 'react-hook-form';
// import APFileModal from './ExcelFileInput/APFileModal';
import APFileUploaded from './ExcelFileInput/APFileUploaded';
import APDragAndDrop from './ExcelFileInput/APDragAndDrop';
import APFileModal from './ExcelFileInput/APFileModal';
import { toast } from 'react-toastify';
import { DefaultButton } from '../buttons';
// import { toast } from 'react-toastify';

interface Props {
    id?: string;
    label?: string;
    name: string;
    control: Control<any>;
    defaultValue?: File;
    rules?: object;
    // width?: string;
    // showModal?: boolean;
    isCircle?: boolean;
    type?: 'image' | 'excel';
    reset?:boolean;
    imageUrl?:string;
}

const FileInput: React.FC<Props> = ({ id, label, name, control, defaultValue = null, rules, isCircle = false, type, reset=false, imageUrl }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [tableData, setTableData] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);

    const handleFileChange = (file: File) => {
        if (type === 'image' && !file.type.startsWith('image/')) {
            toast.warn('Please select a valid image file.');
            return;
        }

        setSelectedFile(file);
        processFile(file);
    };

    const processFile = (file: File) => {
        if (type === 'image') {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                setImagePreviewUrl(event.target.result); // Store the image preview URL
                setFileUploaded(true);
            };
            reader.readAsDataURL(file);
        }
        else if (type === 'excel') {
            const reader = new FileReader();
        reader.onload = (event: any) => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' });
            const worksheet = workbook.Sheets['Catalog Import'];
            if (worksheet) {
                const headers: any = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
                const json: any = XLSX.utils.sheet_to_json(worksheet, { defval: null });
                processTableData(headers, json);
            } else {
                toast.error('Catalog Import sheet not found.');
            }
        };
        reader.readAsBinaryString(file);
        }
    };

    const processTableData = (headers: string[], data: any[]) => {
        const col = headers.map((header) => ({
            field: header,
            headerName: header,
        }));

        const rowData = data.map((row, index) => ({
            id: index,
            ...row,
        }));

        setColumns(col);
        setTableData(rowData);
        setModalOpen(true);
    };

    const handleCancelSelection = () => {
        setFileUploaded(false);
        setSelectedFile(null);
        setImagePreviewUrl(null);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setColumns([]);
        setTableData([]);
        setSelectedFile(null);
    };

    const handleUploadAssets = (onChange: (data: { filename: string; columns: any[]; tableData: any[] }) => void) => {
        setFileUploaded(true);
        setModalOpen(false); // Close modal after uploading

        // Prepare JSON object to return
        const jsonData = {
            filename: selectedFile?.name ?? '',
            columns,
            tableData,
        };

        // Call the onChange function if provided
        if (onChange) {
            onChange(jsonData);
        }
    };

    useEffect(()=>{
        if(reset){
            setColumns([]);
            setTableData([]);
            setSelectedFile(null);
            setImagePreviewUrl(null);
            setFileUploaded(false);
        }
    },[reset]);

    useEffect(()=>{
        if(imageUrl){
            setImagePreviewUrl(imageUrl);
            setFileUploaded(true)
        }
    },[imageUrl])

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field, fieldState }) => (
                <Box
                    sx={{
                        height: '100%',
                        width: '100%'
                    }}
                >
                    {!fileUploaded ? (
                        <Box
                            id={id}
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: isCircle ? '100%' : '8px',
                                border: '1px dashed #ccc',
                                backgroundColor: '#FCFCFC'
                            }}
                        >
                            <APDragAndDrop
                                label={label}
                                isCircle={isCircle}
                                onBlur={field.onBlur}
                                onChange={field.onChange}
                                handleFileChange={handleFileChange}
                            />
                            {fieldState.error && (
                                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                    {fieldState.error.message}
                                </Typography>
                            )}
                        </Box>
                    ) : (
                        <Box sx={{ width: '100%' }}>
                            {type === 'image' && imagePreviewUrl && (
                                <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                                    <Box
                                        component="img"
                                        src={imagePreviewUrl}
                                        alt="Selected file"
                                        sx={{
                                            width: '120px',
                                            height: '120px',
                                            borderRadius: isCircle ? '100%' : '8px',
                                        }}
                                    />
                                    <DefaultButton 
                                        text={'Edit'}  
                                        onClick={()=>setFileUploaded(false)}                                      
                                    />
                                </Box>
                            )}
                            {type === 'excel' && <Box sx={{width:'25%'}}><APFileUploaded selectedFile={selectedFile!} handleCancelSelection={handleCancelSelection} /></Box>}
                        </Box>
                    )}
                    <APFileModal 
                        modalOpen={modalOpen}
                        handleModalClose={handleModalClose}
                        tableData={tableData}
                        columns={columns}
                        handleUploadAssets={()=>handleUploadAssets(field.onChange)}
                    />
                </Box>
            )}
        />
    );
};

export default FileInput;
