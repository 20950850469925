import { useEffect, useRef } from "react";

const ScrollToBottom = ({ dependencies = [] }) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView(); // Optional: Add smooth scrolling
    }
  }, [dependencies]); // Depend on props or state that trigger re-renders

  return <div ref={elementRef} style={{ height: '1px' }} />; // Keep the div minimal in height
};

export default ScrollToBottom;
