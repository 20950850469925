import { Modal, Box, Typography } from "@mui/material";
import { APIconButton } from "../../buttons";
import APTable from "../../table/APTable";
import { IColDef } from "../../table/tableInterfaces";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

interface Props{
    modalOpen: boolean;
    handleModalClose: ()=>void;
    tableData: any[];
    columns: IColDef[];
    handleUploadAssets: () => void;
}
const APFileModal:React.FC<Props> = ({modalOpen, handleModalClose, tableData, columns, handleUploadAssets}) => {

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            disableEscapeKeyDown
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'white',
                    width: '90%',
                    maxWidth: '90%',
                    maxHeight: '90%',
                    overflow: 'auto',
                    borderRadius: '8px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '20px',
                    }}
                >
                    <Typography variant="h6" sx={{ fontSize: '25px', fontWeight: 'bold' }}>
                        {tableData.length} Assets
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <APIconButton
                            text={'Cancel'}
                            icon={<CloseIcon />}
                            whiteBackground
                            onClick={handleModalClose}
                        />
                        <APIconButton
                            text={'Upload asset(s)'}
                            icon={<CheckIcon />}
                            onClick={handleUploadAssets}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        height: '90%',
                        width: '100%',
                        overflow: 'auto',
                    }}
                >
                    <APTable columns={columns} data={{rowData: tableData, rowCount: tableData.length}} />
                </Box>
            </Box>
        </Modal>
    )
}

export default APFileModal;