import { Box } from "@mui/material"
import APDivider from "../../components/APDivider"
import { Control } from "react-hook-form";
import { RichTextInput } from "../../components/inputs";

interface Props{
  control: Control<any>;
}

const MarketConditionAnalyze:React.FC<Props> = ({control}) => {
  return (
    <Box>
      <APDivider text="Market conditions analyzed" margin="40px 0px"/>
      <RichTextInput id="market_conditions" name="market_conditions" control={control} />
    </Box>
  )
}

export default MarketConditionAnalyze