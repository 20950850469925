import * as React from 'react';
import { Box, Radio, FormControlLabel, Typography, useTheme, RadioGroup } from '@mui/material';
import { styled } from '@mui/system';
import { Control, Controller } from 'react-hook-form';

interface StyledRadioProps {
  size?: 'small' | 'medium';
}

const StyledRadio = styled((props: StyledRadioProps & React.ComponentProps<typeof Radio>) => (
  <Radio {...props} />
))(({ size }) => ({
  color: '#4F4F4F',
  '&.Mui-checked': {
    color: '#007BFF', // Custom color when checked
  },
  '& .MuiSvgIcon-root': {
    fontSize: size === 'small' ? '1.2rem' : '1.5rem',
  },
}));

interface LabeledRadioGroupProps {
  id?: string;
  label?: string;
  name: string;
  control: Control<any>;
  size?: 'small' | 'medium';
  defaultValue?: string;
  rules?: object;
  options: Array<{ label: string; value: string }>; // Required for dynamic radio buttons
  layout?: 'row' | 'column'; // New layout prop
}

const LabelRadio: React.FC<LabeledRadioGroupProps> = ({
  id, label, name, size = 'medium', control, defaultValue = '', rules, options, layout = 'row'
}) => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  return (
    <Box sx={{ mb: 2, width: '100%' }}>
      {label && <Typography component="label" htmlFor={id} variant="body1" sx={{ mb: 1, fontSize: '12px', color: '#4F4F4F' }}>
        {label}
      </Typography>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState }) => (
          <RadioGroup
            {...field}
            row={layout === 'row'}
            sx={{
              display: 'flex',
              flexDirection: layout === 'row' ? 'row' : 'column', // Control row/column layout
              gap: 1,
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <StyledRadio
                    size={size}
                    id={id ? `${id}-${option.value}` : undefined}
                    sx={{
                      color: fieldState.error ? 'red' : primaryColor,
                      '&.Mui-checked': {
                        color: primaryColor, // Custom checked color
                      },
                    }}
                  />
                }
                label={option.label}
              />
            ))}
          </RadioGroup>
        )}
      />
    </Box>
  );
};

export default LabelRadio;
