import { Box, Typography } from '@mui/material'
import React from 'react'
import APDivider from '../../components/APDivider'
import { RichTextInput } from '../../components/inputs'
import { Control } from 'react-hook-form';

interface Props{
  control: Control<any>;
}
const HypotheticalConditions:React.FC<Props> = ({control}) => {
  return (
    <Box>
      <APDivider text='Hypothetical conditions' margin='40px 0px'/>
      <Typography sx={{fontSize:'16px', color:'#212529', mb:'40px'}}>A hypothetical condition is defined as a condition, directly related to a specific assignment, which is contrary to what is known by the appraiser to exist on the effective date of the assignment results, but is used for the purpose of analysis.</Typography>
      <RichTextInput id="hypothetical_contitions" name="hypothetical_contitions" control={control} />
    </Box>
  )
}

export default HypotheticalConditions