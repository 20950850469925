import { Box } from "@mui/material"
import APDivider from "../../components/APDivider"
import { Control } from "react-hook-form";
import { LabelCheckboxInput } from "../../components/inputs";
import { APPROACHED_TO_VALUE_TYPES } from "../../Constant";

interface Props{
  control: Control<any>;
}

const ApproachesToValue:React.FC<Props> = ({control}) => {
  return (
    <Box>
      <APDivider text='Approaches to value' margin="40px 0px"/>
      <LabelCheckboxInput
        name="approaches_to_value"
        control={control}
        options={APPROACHED_TO_VALUE_TYPES}
      />
    </Box>
  )
}

export default ApproachesToValue