// src/pages/OTPPage.tsx

import { Box, Typography, Button } from '@mui/material';
import * as React from 'react';
import { OTPInput } from '../../components/inputs';

const OTPForm: React.FC = () => {
  // const [otp, setOtp] = React.useState('');

  const handleChange = (otpValue: string) => {
    // setOtp(otpValue);
  };

  // const handleSubmit = () => {
  //   console.log('OTP entered:', otp);
  //   // Add your submit logic here
  // };
  return (

    <Box>
      <Typography component="h1" variant="h6" sx={{ fontWeight:'bold', mb:4 }}>
        Let’s get you confirmed!            
      </Typography>
      <Typography variant="body2" sx={{ mb: 6,pr:6}}>A six digit code has been sent to <span style={{fontWeight:'bold'}}>okatakyie.opoku-badu@purplewave.com</span>, kindly input to proceed.</Typography>

      <Box sx={{mr:10}}>
      <OTPInput label='Enter code' length={6} onChange={handleChange} />

      <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, height: '45px' }}
        >
          Submit
      </Button>
      </Box>
    </Box>
          
  );
 };

export default OTPForm;
