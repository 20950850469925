import './PreviewReport.css';
import { IReportForm } from '../reportInterface';
import DOMPurify from 'dompurify';
import { USPAP_CERTIFICATION, USPAP_TERMS, DISCLOSURES } from '../../../Constant';
import { getCurrentDate } from '../../../utils/helpers';

interface Props{
  pdfRef: React.RefObject<HTMLDivElement>;
  reportResponse: any;
  report:IReportForm;
  onBackClick: ()=>void;
}
const UspapReportPreviewPage:React.FC<Props> = ({pdfRef, reportResponse, report, onBackClick}) => {
  // console.log(reportResponse);
  if (!report) {
    return <p>No report data available</p>;
  }
  
  const valuations = reportResponse.request.total_estimates.map((es:any)=>es.estimate_type);
  // console.log(report)
  // console.log(valuations);

  // Destructure report
  const {
    introduction,
    intended_use,
    intended_user,
    effective_date,
    report_date,
    inspection_date,
    restriction_type,
    work_scope,
    approaches_to_value,
    market_condition_analyzed,
    sources,
    assumptions,
    hypothetical_conditions,
    limiting_conditions,
    appraiser_qualifications,
    file,
  } = report;

  const sanitizeHTML = (html: string): { __html: string } => {
    return { __html: DOMPurify.sanitize(html) };
  };

  return (
    <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
      <div style={{
        width:'100%'
      }}>
        <button onClick={onBackClick}>Back</button>
      </div>
      <div style={{width:'60%'}}>
        <div className='container' ref={pdfRef}>
        {/* Page 1 */}
        <div className="page1 page-break">
          <div className='logo'>
            <img
              src={'/assets/images/logo.png'}
              alt="Purplewave Logo"
              style={{ height: '50%',width:'50%' }} 
            />
          </div>
          <div className='p1s1'>
            <h3>Prepared By:</h3>
            <p className='bold'>Purple Wave Appraisal Services</p>
            <p>825 Levee Dr., Manhattan, KS 66502</p>
          </div>
          <div className='p1s2'>
            <h3>{reportResponse?.request.manager?.name}</h3>
            <p>Appraiser</p>
          </div>

          <div className='p1s3'>
            <p className='bold'>Purple Wave Appraisal Services</p>
            <p>825 Levee Dr., Manhattan, KS 66502</p>
            <p>
              Toll Free: 866-608-9283 &nbsp;&nbsp;|&nbsp;&nbsp; Local: 785-537-7653
            </p>
            <p>
              Email: <a href="mailto:appraisals@purplewave.com">appraisals@purplewave.com</a> &nbsp;&nbsp;|&nbsp;&nbsp;
              Website: <a href="https://www.purplewave.com/appraisals">www.purplewave.com/appraisals</a>
            </p>
          </div>
        </div>

        {/* Page 2 */}
        <div className="page-break">
          <div className='p2s1'>
            <div>
              <p>Jaxon5 Imports, LLC</p>
              <p>20402 Aspenwilde Dr.</p>
              <p>Cypress, TX 77433</p>
            </div>
            <p>Date: December 31st, 2022</p>
          </div>

          <p className='salutation'>To whom it may concern:</p>
          <p>
            Purple Wave Appraisal Services is presenting this appraisal report on
            the assets for <span>{reportResponse.request.customer_name}</span>. The purpose of this appraisal was to
            establish the <span dangerouslySetInnerHTML={sanitizeHTML(introduction)} />
          </p>
          {/* <div dangerouslySetInnerHTML={sanitizeHTML(introduction)} /> */}
          <p>
            This report has been prepared in compliance with 2020-2021 Uniform
            Standards of Professional Appraisal Practice (USPAP) standards. </p>
          <p>
            The opinion of values is based on the training and experience as appraisers,
            our company's research databases, internet, and local market searches.
          </p>
          <p>
            Purple Wave Appraisal Services was informed that<span> {reportResponse.request.customer_name} </span>
            owns the assets included in this report, but the appraiser makes no
            warranties or representations as to title.
          </p>
          <p>
            Based on the assets' condition, the prevailing economic market conditions and 
            market analyses, it is our unbiased opinion that the value of both the assets is:
          </p>

          <table className="value-table">
            <thead>
              <tr>
                {
                  reportResponse?.request.total_estimates.map((valuation:any)=>(
                    <th key={valuation}>{valuation.estimate_type.toUpperCase()}</th>
                  ))
                }
                {/* <th>Fair Market Value</th>
                <th>Orderly Liquidation Value</th>
                <th>Forced Liquidation Value</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
              {
                  reportResponse?.request.total_estimates.map((valuation:any)=>(
                    <td key={valuation}>${valuation.total_value}</td>
                  ))
                }
                {/* <td>$81,500</td>
                <td>$65,750</td>
                <td>$52,750</td> */}
              </tr>
            </tbody>
          </table>

          <p>These values are subject to the assumptions, definitions and limiting conditions detailed in the attached appraisal report.</p>
          <p>Thank you for the opportunity to be of service.</p>

          <p>Sincerely,</p>
          <div className='signature'>
            <p>
              Electronic Signature Notice: This document has been transmitted electronically to 
              my client and includes my signature in electronic form. I affirm that I maintain 
              sole personal control over the use of the electronic signature appended hereto. 
              Electronically affixing my signature to this report carries the same level of 
              authenticity and responsibility for this report's content, analysis and conclusions 
              as would applying an original ink signature on a paper copy of this report.
            </p>
            <div>
              <h4>Signature</h4>
              <div style={{
                width:'150px',
                height:'50px'
              }}>
                <img src={reportResponse.manager_signature} alt={reportResponse.request.manager.name} width={'100%'} height={'100%'}/>
              </div>
              <h4>Date signed</h4>
              <p>{getCurrentDate()}</p>
            </div>
          </div>
        </div>

        {/* Page 3 */}
        <div className="page-break">
          <h3>Client:</h3>
          <p>{reportResponse.request.customer_name}</p>
          <h3>Intended Use:</h3>
          <span dangerouslySetInnerHTML={sanitizeHTML(intended_use)} />
          {/* <p>{intended_use}</p> */}
          <h3>Intended User:</h3>
          <p>{intended_user}</p>
          <div className='flex-row gap-1'>
            <h3>Effective Date:</h3>
            <p>{effective_date}</p>
          </div>
          <div className='flex-row gap-1'>
            <h3>Report Date:</h3>
            <p>{report_date}</p>
          </div>
          <div className='flex-row gap-1'>
            <h3>Inspection Date:</h3>
            <p>{inspection_date}</p>
          </div>

          <h3>Appraisal Type:</h3>
          <p>
            There are two types of appraisal reports. Restricted appraisal report and 
            appraisal report. Restricted appraisal report is defined as a report that is 
            restricted in that the client is the only intended user of the report.
          </p>
          <p>This report is an appraisal report; it is {restriction_type?'a restricted':'not a restricted'} appraisal report.</p>

          <h3>Scope of Work:</h3>
          <span dangerouslySetInnerHTML={sanitizeHTML(work_scope)} />
          {/* <p>{work_scope}</p> */}
        </div>

        {/* Page 4 */}
        <div className="page-break">
          <h3>Definition of Value:</h3>
          <p>
            The “Fair Market Value”, “Orderly Liquidation Value” and “ Forced Liquidation 
            Value” as defined by the National Equipment Appraisal are:
          </p>
          <ul>
            {valuations.includes("fmv") && <li>
              <strong>Fair Market Value</strong> is an opinion expressed in terms of money, 
              at which the property would change hands between a willing buyer and a willing 
              seller, neither being under any compulsion to buy or to sell and both having 
              reasonable knowledge of relevant facts, as of a specific date.
            </li>}
            {valuations.includes("olv") &&  <li>
              <strong>Orderly Liquidation Value</strong> is an opinion of the gross amount, expressed in terms 
              of money, that typically could be realized from a liquidation sale, given a 
              reasonable period of time to find a purchaser (or purchasers), with the seller 
              being compelled to sell on an as-is, where-is basis, as of a specific date.
            </li>}
            { valuations.includes("flv")&& <li>
              <strong>Forced Liquidation Value</strong> is an opinion of the gross amount, expressed in terms 
              of money, that typically could be realized from a properly advertised and 
              conducted public auction, with the seller being compelled to sell with a sense 
              of immediacy on an as-is, where-is basis as of a specific date.
            </li>}
          </ul>

          <h3>Approaches to Value:</h3>
          <p>
            There are three approaches to value commonly used in the appraisal industry. 
            They are:
          </p>
          <ul>
          {approaches_to_value?.includes('sale_comparison') &&<li>
              <strong>Sales Comparison:</strong> A procedure to conclude an opinion of value for a property by 
              comparing it with similar properties that have been sold or are for sale in the 
              relevant marketplace by making adjustments to prices based on marketplace 
              conditions and the properties' characteristics of value.
            </li>}
            {approaches_to_value?.includes('cost') &&<li>
              <strong>Cost:</strong> A procedure to estimate the current costs to reproduce or
              create a property with another of comparable use and marketability.
            </li>}
            {approaches_to_value?.includes('income') &&<li>
              <strong>Income:</strong> A procedure to conclude an opinion of present value by 
              calculating the anticipated monetary benefits (such as a stream of income) for 
              an income-producing property.
            </li>}
          </ul>
          <p>
            For this report the Sales Comparison method was determined to be the best fit for 
            current fair market value. Income and Cost approaches were considered, but not 
            deemed as appropriate methods of value.
          </p>
          <h3>Market Conditions and Markets Analyzed:</h3>
          <span dangerouslySetInnerHTML={sanitizeHTML(market_condition_analyzed)} />
          {/* <p>
            {market_condition_analyzed}
          </p> */}
          <h3>Sources:</h3>
          <span dangerouslySetInnerHTML={sanitizeHTML(sources)} />
          {/* <p>{sources}</p> */}
        </div>

        {/* Page 5 */}
        <div className="page-break">
          <h3>Extraordinary Assumptions:</h3>
          <p>
            An extraordinary assumption is an assignment-specific assumption as of the 
            effective date regarding uncertain information used in an analysis which, if 
            found to be false, could alter the appraiser’s opinions or conclusions. 
          </p>
          <span dangerouslySetInnerHTML={sanitizeHTML(assumptions)} />
          {/* <p>{assumptions}</p> */}

          <h3>Hypothetical Conditions:</h3>
          <p>
            A hypothetical condition is defined as a condition, directly related to a specific 
            assignment, which is contrary to what is known by the appraiser to exist on the 
            effective date of the assignment results, but is used for the purpose of analysis.
          </p>
          <p>{hypothetical_conditions}</p>

          <h3>Limiting Conditions:</h3>
          <span dangerouslySetInnerHTML={sanitizeHTML(limiting_conditions)} />
          {/* <p>{limiting_conditions}</p> */}

          <h3>Appraiser's Qualifications:</h3>
          <span dangerouslySetInnerHTML={sanitizeHTML(reportResponse.request.manager.bio)} />
          {/* <div>{reportResponse.request.manager.bio}</div> */}
          {/* <ul>
            {
              QUALIFICATIONS.map((item)=>(
                <li>{item}</li>
              ))
            }

          </ul> */}
        </div>

        <div className='page-break'>
          <h2 className='text-center text-underline'>USPAP Signed Certifications</h2>
            <p>I certify that, to the best of my knowledge and belief:</p>
            <ul>
              {
                USPAP_CERTIFICATION.map((item)=>(
                  <li key={item}>{item}</li>
                ))
              }
            </ul>
        </div>

        <div className="page-break">
          <h3>Definitions and Terms from USPAP</h3>
          <p>
            Various terms are used throughout the appraisal report. The following are 
            definitions of the terms:	
          </p>
          {
            USPAP_TERMS.slice(0,15).map((item)=>(
              <p key={item.term}>
                <strong>{item.term}</strong> - {item.definition}
              </p>
            ))
          }
        </div>

        <div className="page-break">
          {
            USPAP_TERMS.slice(15,27).map((item)=>(
              <p key={item.term}>
                <strong>{item.term}</strong> - {item.definition}
              </p>
            ))
          }
        </div>

        <div className="page-break">
          {
            USPAP_TERMS.slice(27,39).map((item)=>(
              <p key={item.term}>
                <strong>{item.term}</strong> - {item.definition}
              </p>
            ))
          }
        </div>

        <div className='page-break'>
          <h3>USPAP Compliance Statement</h3>
          <p>
            This appraisal report complies with the reporting requirements as defined under 
            Standards Rule 2-2 (b) and 8-2 (b) of the 2020-2021 Uniform Standards of 
            Professional Appraisal Practice (USPAP) for an Appraisal Report.
          </p>

          <h3>Confidentiality and Privacy</h3>
          <p>
            Purple Wave Appraisal Services will maintain the conformity and privacy of 
            customer information obtained in the course of this assignment in compliance with 
            USPAP.
          </p>
          <p>
            We do not sell information about our clients to others.  We protect the security 
            and confidential information about the client.  We share information outside of 
            our company only when necessary to administer products or services we provide 
            when we have your permission, or when required or permitted by law.
          </p>

          <h3>Records Retention Statement</h3>
          <p>
            An appraiser must prepare a workfile for each appraisal or appraisal review 
            assignment. A workfile must be in existence prior to the issuance of any report 
            or other communication of  assignment results. A written summary of an oral 
            report must be added to the workfile within a reasonable time after the issuance 
            of the oral report.
          </p>
          <p>The workfile must include:</p>
          <ul>
            <li>
              the name of the client and the identity, by name or type, of any other intended users;
            </li>
            <li>
              true copies of all written reports, documented on any type of media. 
              (A true copy is a replica of the report transmitted to the client. A photocopy 
              or an electronic copy of the entire report transmitted to the client satisfies 
              the requirement of a true copy.);
            </li>
            <li>
              summaries of all oral reports or testimony, or a transcript of testimony, 
              including the appraiser’s signed and dated certification; and
            </li>
            <li>
              all other data, information, and documentation necessary to support the 
              appraiser’s opinions and conclusions and to show compliance with USPAP, or 
              references to the location(s) of such other data, information, and 
              documentation.
            </li>
          </ul>
          <p>
            A workfile in support of a Restricted Appraisal Report or an oral appraisal report 
            must be sufficient for the appraiser to produce an Appraisal Report. A workfile in 
            support of an oral appraisal review report must be sufficient for the appraiser to 
            produce an Appraisal Review Report.
          </p>
          <p>
            An appraiser must retain the workfile for a period of at least five years after 
            preparation or at least two years  after final disposition of any judicial 
            proceeding in which the appraiser provided testimony related to the assignment, 
            whichever period expires last.
          </p>
          <p>
            An appraiser must have custody of the workfile, or make appropriate workfile 
            retention, access, and retrieval arrangements with the party having custody of 
            the workfile. This includes ensuring that a workfile is stored in a medium that 
            is retrievable by the appraiser throughout the prescribed record retention period.
          </p>
        </div>

        <div className='page-break'>
        <p>
            An appraiser having custody of a workfile must allow other appraisers with 
            workfile obligations related to an assignment appropriate access and retrieval 
            for the purpose of:
          </p>
          <ul>
            <li>submission to state appraiser regulatory agencies;</li>
            <li>compliance with due process of law;</li>
            <li>submission to a duly authorized professional peer review committee; or</li>
            <li>compliance with retrieval arrangements.</li>
              <ul>
                <li>
                  Comment: A workfile must be made available by the appraiser when required 
                  by a state appraiser regulatory  agency or due process of law
                </li>
              </ul>
          </ul>
          <p>Workfile is available upon request</p>

        <h3>Disclosures:</h3>
          {DISCLOSURES.map((item)=>(
            <p key={item}>{item}</p>
          ))}
        </div>
        </div>
      </div>
    </div>

  )
}

export default UspapReportPreviewPage