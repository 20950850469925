import { Box, Typography, useTheme } from "@mui/material";
import { MaterialSymbol } from "react-material-symbols";

interface Props{
    icon:any;
    text:string;
}
const IconText:React.FC<Props> = ({icon, text}) => {
    const theme = useTheme();
    return(
        <Box sx={{display:'flex', alignItems:'center',gap:'5px'}}>
            <MaterialSymbol icon={icon} size={24} color="#323232"/>
            <Typography sx={{fontSize:'14px', color:`${theme.palette.black}90`}}>{text}</Typography>
        </Box>
    )
}

export default IconText;