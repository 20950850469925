import { IConfirmEmail, IForgotPasswordForm, ILoginForm, IResetPasswordForm } from './authInterfaces';
import { CustomAxiosRequestConfig, authAPI } from '../../httpClient';

export const login = async (formValues: ILoginForm, signal?: AbortSignal) => {
  const response = await authAPI.post('/auth/login',formValues);
  return response.data;
}
export const forgotPassword = async (formValues: IForgotPasswordForm) => authAPI.post('/auth/forgot_password', formValues);
export const resetPassword = async (formValues: IResetPasswordForm) => authAPI.post('/auth/reset_password', formValues);
// export const setPassword = async (formValues: IResetPasswordForm) => authAPI.post('/users/accept_invite', formValues,{ applyAuth: false} as CustomAxiosRequestConfig);
export const confirmEmail = async (formValues: IConfirmEmail) => authAPI.post('/confirm-email', formValues);
export const logout = async () => authAPI.post('/logout',{});