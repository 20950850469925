import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  isUpperCase,
  isLowerCase,
  hasNumber,
  hasSpecialChar,
  isMinLength,
  isPasswordMatch,
} from '../../utils/validationRules';

const PasswordValidationChecklist: React.FC<{ password: string; password_confirmation: string }> = ({ password, password_confirmation }) => {
  const validations = [
      { check: isMinLength(12)(password) === true, label: 'At least 12 characters long' },
    { check: isUpperCase(password) === true, label: 'One uppercase letter' },
    { check: isLowerCase(password) === true, label: 'One lowercase letter' },
    { check: hasNumber(password) === true, label: 'One number' },
    { check: hasSpecialChar(password) === true, label: 'One special character' },
    { check: password && password_confirmation && isPasswordMatch(password)(password_confirmation) === true, label: 'Passwords must match' },
  ];

  return (
    <Box sx={{ mb: 4 }}>
      {validations.map((validation, index) => (
        <Box key={validation.label} display="flex" alignItems="center" sx={{ mb: 1 }}>
            <CheckCircleIcon sx={{ color: validation.check?'green':'#CED4DA', mr: 1, height:18,width:18 }} />
          <Typography variant="body2" sx={{ color: validation.check ? 'green' : 'red', fontSize:'12px' }}>
            {validation.label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default PasswordValidationChecklist;
