import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MaterialSymbol } from "react-material-symbols";
import SlidingPanel from "react-sliding-side-panel";
import { IComment } from "../assets/assetsInterface";
import { DefaultButton } from "../../components/buttons";
import dayjs from "dayjs";
import ScrollToBottom from "../../components/ScrollToBottom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getComments, postComment } from "./commentsQueries";
import { handleError } from "../../utils/errorHandler";
import { toast } from "react-toastify";
import CommentItem from "./CommentItem";
import { isArray } from "lodash";
import { useRequestStore } from "../requests/requestStore";

interface Props {
  onOpen: boolean;
  onClose?: () => void;
  asset?: any;
}

const AssetComment: React.FC<Props> = ({ onOpen, asset, onClose, }) => {
  // console.log(asset)
  // console.log('open assset: ',onOpen)
  const [inputValue, setInputValue] = useState("");
  const [isRange, setIsRange] = useState(false);
  const [minValue, setMinValue] = useState<string>("");
  const [maxValue, setMaxValue] = useState<string>("");
  const [groupedComments, setGroupedComments] = useState<{ [key: string]: IComment[] }>({});
  const selectedRequest = useRequestStore.getState().selectedRequest;

  const { data: comments, isLoading, refetch } = useQuery(
    {
      queryKey: ['comment', asset?.id],
      queryFn: () => getComments(selectedRequest.id, asset?.id,{ 'filters[asset]': asset?.id }),
      enabled: onOpen
    }
  );

  const { mutate: postCommentMutation } = useMutation({
    mutationKey: ['postCommentMutation'],
    mutationFn: ({ formValues }: any) => postComment(selectedRequest.id, asset?.id,formValues),
    onSuccess: (res) => {
      if (res.status === 204) {
        toast.error('Comment not sent');
      } else {
        refetch();
        setInputValue("");
        setMinValue(""); // Reset min value
        setMaxValue(""); // Reset max value
      }
    },
    onError: handleError,
  });

  useEffect(() => {
    if (comments?.data.data) {
      if (!isArray(comments.data.data)) return;
      const newGroupedComments = comments.data.data.reduce((acc: { [key: string]: IComment[] }, comment: IComment) => {
        const createdDate = dayjs(comment.created_at);
        const today = dayjs();
        const yesterday = today.subtract(1, "day");

        let header = '';
        if (createdDate.isSame(today, 'day')) {
          header = 'Today';
        } else if (createdDate.isSame(yesterday, 'day')) {
          header = 'Yesterday';
        } else {
          header = createdDate.format('DD/MM/YYYY');
        }

        if (!acc[header]) {
          acc[header] = [];
        }

        acc[header].push(comment);
        return acc;
      }, {});

      for (const key in newGroupedComments) {
        newGroupedComments[key] = newGroupedComments[key].reverse(); // Reverse each group
      }

      setGroupedComments(newGroupedComments);
    }
  }, [comments]);

  const handleSendComment = () => {
    const formValues = { content: '', asset: asset?.id, is_range: isRange };
  
    if (isRange) {
      const minNum = parseFloat(minValue);
      const maxNum = parseFloat(maxValue);
  
      if (!isNaN(minNum) && !isNaN(maxNum)) {
        if (minNum === maxNum) {
          formValues.content = `${minNum}`;
        } else {
          formValues.content = `${minNum}-${maxNum}`;
        }
      }
    } else if (inputValue.trim()) {
      formValues.content = inputValue.trim();
    }
  
    if (formValues.content) {
      postCommentMutation({ formValues });
    } else {
      toast.error("Cannot send an empty message");
    }
  };
  

  return (
    <SlidingPanel type={"right"} isOpen={onOpen} size={25} noBackdrop>
      <Box sx={{ height: "100%", alignContent: "end", borderLeft:'1px solid #ccc' }}>
        <Box sx={{ bgcolor: "white", height: "93.5vh", p: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #EBEBEB",
              pb: "10px",
            }}
          >
            <Typography>Asset comments</Typography>
            <Box onClick={onClose} sx={{ cursor: "pointer" }}>
              <MaterialSymbol icon="close" size={16} />
            </Box>
          </Box>

          {isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
              <CircularProgress />
              <Typography>Loading comments</Typography>
            </Box>
          )}

          {!isLoading && comments?.data.length === 0 && (
            <Box>
              <Typography sx={{ opacity: 0.5, fontSize: '12px', mt: 2 }}>No comments</Typography>
            </Box>
          )}

          {!isLoading && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '97.5%',
            }}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                justifyContent: 'end',
                height: '80vh',
                overflowY: 'auto', // Allow vertical scroll
                pb: 2,
                '&::-webkit-scrollbar': {
                  display: 'none', // Hide scrollbar in Chrome/Safari
                },
                scrollbarWidth: 'none', // Hide scrollbar in Firefox
              }}>
                {Object.entries(groupedComments).reverse().map(([dateHeader, comments]) => (
                  <Box key={dateHeader}>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mb: 2,
                      borderTop: '1px solid #f2f2f2',
                      mt: 4,
                      pt: 2
                    }}>
                      <Box sx={{
                        backgroundColor: '#F2F2F2',
                        py: '4px',
                        px: '8px',
                        borderRadius: '40px',
                        width: 'fit-content',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        alignSelf: 'center'
                      }}>
                        <Typography sx={{ fontSize: '12px' }}>{dateHeader}</Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      {comments.map((comment) => (
                        <CommentItem key={comment.created_at} comment={comment} />
                      ))}
                    </Box>
                    <ScrollToBottom />
                  </Box>
                ))}
              </Box>

              <Box sx={{
                border: '1px solid #EBEBEB',
                borderRadius: '8px',
                p: '12px',
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'white'
              }}>
                {!isRange && (
                  <Box sx={{
                    maxHeight:'100px',
                    overflow:'auto'
                  }}>
                    <TextField
                      sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none', // Remove border for outlined variant
                          },
                        },
                      }}
                      placeholder="Type message..."
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      multiline
                      // onKeyPress={(e) => e.key === 'Enter' && handleSendComment()}
                    />
                  </Box>
                
                )}
                {isRange && (
                  <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                    <TextField
                      placeholder="Min"
                      sx={{
                        flex: 1,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border:'none',
                            borderRight: '1px solid #ccc', // Remove border for outlined variant
                          },
                        },
                      }}
                      value={minValue}
                      onChange={(e) => setMinValue(e.target.value)}
                    />
                    
                    <TextField
                      placeholder="Max"
                      sx={{
                        flex: 1,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none', // Remove border for outlined variant
                            borderRadius:0
                  
                          },
                        },
                      }}
                      value={maxValue}
                      onChange={(e) => setMaxValue(e.target.value)}
                    />

                  </Box>
                )}
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '28px',
                }}>
                  <Box>
                    <DefaultButton
                      text={`Enter ${isRange?'text':'range ($)'}`}
                      isRounded
                      extrastyles={{
                        backgroundColor: '#F2F2F2',
                        fontSize: '12px',
                        color: 'black',
                        height: '28px',
                        ":hover":{
                          backgroundColor:'#F2F2F2'
                        }
                      }}
                      onClick={() => setIsRange(!isRange)} // Toggle range input
                    />
                  </Box>
                  <Box
                    sx={{
                      bgcolor: (theme) => `${theme.palette.black}50`,
                      borderRadius: '100%',
                      height: '34px',
                      width: '34px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    onClick={handleSendComment} // Send comment on click
                  >
                    <MaterialSymbol icon="send" size={24} color="white" />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </SlidingPanel>
  );
};

export default AssetComment;
