import * as React from 'react';
import { Box, IconButton, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Control, Controller } from 'react-hook-form';

interface StyledTextValidatorProps {
  background?: 'filled' | 'transparent';
  size?: 'small' | 'medium';  // Define possible size values here
}

const StyledTextValidator = styled((props: StyledTextValidatorProps & React.ComponentProps<typeof TextField>) => (
  <TextField {...props} />
))(({ background, size }) => {
  return {
    '& .MuiInputBase-root': {
      backgroundColor: background === 'transparent' ? '#fff' : '#f0f0f0',
      borderRadius: background === 'transparent' ? null : '8px',
      border: background === 'transparent' ? '1px solid #f0f0f0' : 'none',
      height: size === 'small' ? '42px' : null,
      transition: 'border-color 0.3s ease',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  };
});

interface PasswordInputProps {
  id?: string;
  label?: string;
  name: string;
  control: Control<any>;
  defaultValue?: string;
  size?: 'small' | 'medium';
  background?: 'filled' | 'transparent';
  rules?: object;
}

const PasswordInput2: React.FC<PasswordInputProps> = ({ id, label, name, size = 'small', background = 'transparent', control, defaultValue = '', rules }) => {
  const theme = useTheme();  // Moved useTheme here
  const primaryColor = theme.palette.primary.main;
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <Box sx={{ mb: 2, width: '100%' }}>
      {label && <Typography component="label" htmlFor={id} variant="body1" sx={{ mb: 1, fontSize: '12px', color: '#4F4F4F' }}>
        {label}
      </Typography>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState }) => (
          <StyledTextValidator
            {...field}
            id={id}
            variant="outlined"
            size={size}
            fullWidth
            type={showPassword ? 'text' : 'password'}
            background={background}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : ''}
            sx={{
              '& .Mui-focused': {
                borderColor: primaryColor,  // Apply the primary color on focus
                borderWidth: '1px',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default PasswordInput2;
