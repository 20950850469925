import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export interface CustomAxiosRequestConfig extends AxiosRequestConfig{
    applyAuth?: boolean;
    appType?: boolean;
}

export const authAPI: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_AUTH_BASE_URL,
    // withCredentials: true  // Allow cookies to be sent with requests for CORS
});
authAPI.interceptors.request.use(
    (config:any)=>{
        const token = localStorage.getItem('token');
        if(token && config.applyAuth !== false){
            config.headers['Authorization'] = `Bearer ${token}`
        }
        return config;
    },
    (error:any)=>{
        return Promise.reject(error instanceof Error ? error : new Error(error));
    }
);

export const appAPI: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    // withCredentials: true  // Allow cookies to be sent with requests for CORS
});
appAPI.interceptors.request.use(
    (config:any)=>{
        const token = localStorage.getItem('token');
        if(token && config.applyAuth !== false){
            config.headers['Authorization'] = `Bearer ${token}`
        }
        return config;
    },
    (error:any)=>{
        return Promise.reject(error instanceof Error ? error : new Error(error));
    }
);


