import React from 'react'
import APDivider from '../../components/APDivider'
import { Box, Typography } from '@mui/material'
import { Control } from 'react-hook-form';
import { RichTextInput } from '../../components/inputs';

interface Props{
  control: Control<any>;
}

const ExtraodinaryAssumptions:React.FC<Props> = ({control}) => {
  return (
    <Box>
      <APDivider text='Extraodinary assumptions' margin="40px 0px"/>
      <Typography sx={{fontSize:'16px', color:'#212529', mb:'40px'}}>An extraordinary assumption is an assignment-specific assumption as of the effective date regarding uncertain information used in an analysis which, if found to be false, could alter the appraiser’s opinions or conclusions.</Typography>
      <RichTextInput id="assumptions" name="assumptions" control={control} />
    </Box>
  )
}

export default ExtraodinaryAssumptions