import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import APStatusFilter from "../APStatusFilter";
import { APIconButton } from "../buttons";
import AddIcon from '@mui/icons-material/Add';
import { useState, MouseEvent } from "react";
import { MaterialSymbol } from "react-material-symbols";
import DebouncedSearch from "../DebounceSearch";
import SearchPlus from "../SearchPlus";
import OrganizationSelector from "../OrganizationSelector";

interface StatusFilterItem {
    label: string;
    value: string;
}

interface Props{
    showAdd?:boolean;
    isAddWhiteBackground?:boolean;
    showSearch?:boolean;
    showSearchPlus?:boolean;
    showStatusFilter?:boolean;
    showFilters?:boolean;
    showOrganizationFilter?:boolean;
    imageSrc?:string;
    statusFilterItems?: any;
    filterItems?:any;
    addText?:string;
    searchEndpoint?:string;
    refetchOnSearchCleared?:boolean;
    onOrganizationSelect?:(orgVal:any)=>void;
    searchPlusAction?:()=>void;
    onSearchResult?:(results:any)=>void;
    addAction?:(event:any)=>void;
    statusFilterAction?:(val:string)=>void;
    filterAction?:(val:string)=>void;
}

const APTableFilter:React.FC<Props> = ({
    showAdd=false,
    isAddWhiteBackground=false,
    showSearch=false,
    showSearchPlus=false,
    showStatusFilter=false,
    showFilters=false,
    showOrganizationFilter=false,
    statusFilterItems=[],
    filterItems=[],
    addText='',
    searchEndpoint,
    refetchOnSearchCleared,
    onSearchResult,
    searchPlusAction=()=>{},
    addAction=()=>{},
    statusFilterAction=()=>{},
    filterAction=()=>{},
    onOrganizationSelect
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (value: string) => {
    if (statusFilterAction) {
      statusFilterAction(value);
    }
    handleCloseMenu();
  };
  
    return(
        <Box sx={{
            width:'100%',
            display:'flex',
            justifyContent:showFilters ? 'space-between' : 'flex-end',
            alignItems:'center',
        }}>
          <Box sx={{display:'flex', alignItems:'center', gap:2}}>
            {showFilters && <APStatusFilter filters={filterItems} onStatusClick={filterAction} />}
            {showOrganizationFilter && <OrganizationSelector onSelect={onOrganizationSelect}/>}
          </Box>
            <Box sx={{
                display:'flex',
                alignItems:'center',
                justifyContent:'flex-end',
                gap:1,
            }}>
                {showStatusFilter && (
                    <Box sx={{ position: 'relative' }}>
                    <IconButton
                      color="primary"
                      aria-label="filter"
                      onClick={handleOpenMenu}
                      sx={{
                        bgcolor: 'primary.main',
                        color: 'white',
                        '&:hover': {
                          bgcolor: 'primary.dark'
                        }
                      }}
                    >
                      <MaterialSymbol
                            icon={'filter_alt'}
                            size={24}
                        />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      {statusFilterItems.map((item:StatusFilterItem) => (
                        <MenuItem key={item.value} onClick={() => handleMenuClick(item.value)}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                )}
                {showSearchPlus && <SearchPlus searchPlusAction={searchPlusAction}/> }
                {showSearch && <Box><DebouncedSearch name="org-search" onResultsFetched={onSearchResult} apiEndpoint={searchEndpoint} refetchOnClear={refetchOnSearchCleared}/></Box>}
                {showAdd && <Box><APIconButton
                    whiteBackground={isAddWhiteBackground}
                    text={addText}
                    onClick={addAction}
                    icon={<AddIcon />}
                /></Box>}
            </Box>
        </Box>
    )
}

export default APTableFilter;