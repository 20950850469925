import Box from "@mui/material/Box";
import APDivider from "../../components/APDivider";
import { Typography } from "@mui/material";
import { RichTextInput } from "../../components/inputs";
import { Control } from "react-hook-form";

interface Props{
    control: Control<any>;
    customer_name: string;
}
const Introduction:React.FC<Props> = ({control, customer_name}) => {
    return (
        <Box>
            <APDivider text="Introduction" />
            <Box sx={{pt:'20px'}}>
                <Typography sx={{fontSize:'14px', color:'#212529'}}>To whom it may concern:</Typography>
                <Typography sx={{fontSize:'14px', color:'#212529'}}>Purple Wave Appraisal Services is presenting this appraisal report on the assets for <span style={{color:'#9747FF', fontWeight:'bold'}}>{customer_name}.</span> The purpose of this appraisal was to establish the...</Typography>
                <Box sx={{mt:'40px'}}>
                    <RichTextInput id="introduction" name="introduction" control={control} />
                </Box>
            </Box>
        </Box>
    )
}
export default Introduction;