import RequestTable from "../requests/RequestTable";

interface Props{
    isLoading?: boolean;
    data: any[];
    onDelete?:(row:any)=>void;
}
const DashboardTable:React.FC<Props> = ({isLoading, data, onDelete}) => {
      return (
        <RequestTable data={{rowData: data, rowCount:5}} rowPerPage={5} onDelete={onDelete} isLoading={isLoading}/>
      )
}

export default DashboardTable;