import React, { useState } from 'react';
import imageCompression from 'browser-image-compression';
import { useDropzone } from 'react-dropzone';
import ImagePreviewModal from './ImagePreviewModal';
import { IImage } from './assetsInterface';
import { toast } from 'react-toastify';

interface ImagePreviewProps {
  onAttachCompressedImages: (compressedImages: IImage[]) => void;
  onOpenModal?: boolean;
  onModalClose?: () => void;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({
  onAttachCompressedImages,
  onOpenModal = false,
  onModalClose,
}) => {
  const [compressedImages, setCompressedImages] = useState<IImage[]>([]);
  const [mainImage, setMainImage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [open, setOpen] = useState(false);

  const onDrop = (acceptedFiles: File[]) => {
    const imageFiles = acceptedFiles.filter((file) =>
      file.type.startsWith('image/')
    ); // Filter only image files

    if (imageFiles.length === 0) {
      setCompressedImages([]); // Clear images
      toast.warning('No image file found');
      return;
    }
    
    (async () => {
      setOpen(true);
      setIsProcessing(true);
      const allCompressedImages: IImage[] = [];

      for (const file of acceptedFiles) {
        let processedFile = file;

        if (file.size > 500 * 1024) {
          const options = { maxSizeMB: 0.2, maxWidthOrHeight: 1024 };
          try {
            processedFile = await imageCompression(file, options); // Compress file
          } catch (error) {
            console.error('Compression error:', error);
          }
        }

        allCompressedImages.push({
          url: URL.createObjectURL(processedFile),
          filename: processedFile.name,
          size: processedFile.size,
        });
      }

      setCompressedImages(allCompressedImages);
      if (allCompressedImages.length > 0) {
        setMainImage(allCompressedImages[0].url);
      }

      setIsProcessing(false);
    })(); // Immediately invoked async function
  
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
    },
    multiple: true,
  });

  const handleDeleteImage = (index: number) => {
    setCompressedImages((prevImages) => {
      const updatedImages = prevImages.filter((_, i) => i !== index);

      if (mainImage === prevImages[index].url) {
        setMainImage(updatedImages.length > 0 ? updatedImages[0].url : null);
      }

      return updatedImages;
    });
  };

  const handleCloseModal = () => {
    setOpen(false);
    if (onModalClose) onModalClose();
  };

  const handleAttach = () => {
    onAttachCompressedImages(compressedImages);
    setOpen(false);
  };

  return (
    <div>
      <div
        {...getRootProps({ className: 'dropzone' })}
        style={{
          border: '2px dashed #000',
          padding: '20px',
          textAlign: 'center',
          cursor: 'pointer',
        }}
      >
        <input
          {...getInputProps({
            // directory: '',
            // webkitdirectory: '', // Ensure directory attributes are applied here
          })}
          type="file"
        />
        <p>Drag & drop a folder or click to select images</p>
      </div>

      <ImagePreviewModal
        onOpen={open || onOpenModal}
        isProcessing={isProcessing}
        images={compressedImages}
        onClose={handleCloseModal}
        onDelete={handleDeleteImage}
        onAttach={handleAttach}
        showDelete
      />
    </div>
  );
};

export default ImagePreview;
