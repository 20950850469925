import { Box, Typography } from "@mui/material";
import DashboardCard from "./DashbardCard";
import APStatusFilter from "../../components/APStatusFilter";
import DashboardTable from "./DashboardTable";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGeneralStore } from "../../generaStore";
import { useFetchRequests } from "../../hooks/useFetchRequests";
import { flattenObject } from "../../utils/jsonObjectNormalize";
import { STATUS_FILTERS_MAP } from "../../Constant";
import useDeleteRequest from "../../hooks/useDeleteRequest";
import DeleteConfirmationDialog from "../../components/dialogs/DeleteConfirmationDialog";
import APDatePicker from "../../components/APDatePicker";
import { useQuery } from "@tanstack/react-query";
import { getAggregateCount } from "./dashboardQueries";
import { DASHBOARD_CARD_DATA } from "../../data/dashboardData";

const DashboardPage = () => {
    const navigate = useNavigate();
    const setIsPageTransition = useGeneralStore(state=>state.setIsPageTransition);
    const filters: string[] = ['All', 'In progress', 'Awaiting feedback', 'Completed'];
    const [reqData, setReqData] = useState([]);
    const [params, setParams] = useState({limit:5,page:1});
    const [dateParams, setDateParams] = useState({});
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);

    const handleFilterClicked  = (filter:string) => {
        if(filter === 'all'){
            setParams((prev:any) => {
                delete prev['filters[status]'];
                return { ...prev, }
            });
        }
        else{
            setParams((prev) => ({ ...prev, 'filters[status]': STATUS_FILTERS_MAP[filter] }));
        }
    } 
    const {isPending:requestLoading, data:requestResponse,isRefetching, refetch} = useFetchRequests(params);
    const {
        isPending: isGettingAggregate, 
        data:aggregateData, 
        refetch:refetchAggregate, 
        isRefetching:isRefetchingAggregates
    } = useQuery({
        queryKey:['getAggregate',dateParams], 
        queryFn:()=>getAggregateCount(dateParams)}
    );

    const { mutate: deleteRequestMutation, isPending: isRequestDeleting } = useDeleteRequest({
        refetch,
        onSuccess: () => setOpenDeleteDialog(false),
      });

      const handleSingleDelete = (row: any) => {
        setSelectedRow(row);
        setOpenDeleteDialog(true);
      };

      const handleCancelDelete = () => {
        setSelectedRow(null);
        setOpenDeleteDialog(false);
      };

      const handleDateChange = (dates:any) => {
        if(!dates){
            setDateParams({});
        }
        else{
            const formattedDates = dates.map((date:Date) => {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
              });
            setDateParams({'date_range[start]':formattedDates[0],'date_range[end]':formattedDates[1]});
        }
      }

    useEffect(() => {
        refetchAggregate(); 
    }, [dateParams, refetchAggregate]);

    useEffect(()=>{
        setIsPageTransition(true);
    },[setIsPageTransition])

    useEffect(() => {
        // console.log(requestResponse);
        if (!requestLoading && requestResponse) {
            setReqData(requestResponse.data.map((item: any) => flattenObject(item)));
        }
    
        return () => {
            setIsPageTransition(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestLoading, isRefetching, requestResponse]);
    

    return(
        <Box sx={{width:'100%',padding:theme=> theme.spacing(3)}}>
            <Box>
                <Box>
                    <Box sx={{display:'flex', alignItems:'start', justifyContent:'space-between'}}>
                        <Box>
                            <Typography sx={{fontWeight:'bold', fontSize:'24px'}}>Updates</Typography>
                            <Typography sx={{fontSize:'14px', opacity:0.6}}>Get your updates right here on your dashboard!</Typography>
                        </Box>
                        <Box>
                            <APDatePicker onDateChange={handleDateChange} />
                        </Box>
                    </Box>
                    <Box sx={{
                        display:'flex',
                        width:'100%',
                        justifyContent:'space-between',
                        alignItems:'center',
                        marginTop:'20px'
                    }}>
                        {
                            aggregateData?.data.map((item:any)=> (
                            <DashboardCard 
                                key={item.status}
                                status={item.status} 
                                total_request={item.total_requests} 
                                total_assets={item.total_assets}
                                is_loading={isGettingAggregate||isRefetchingAggregates}
                                onClick={(filter)=>navigate('/requests',{state:{filter}})} 
                            />
                        ))
                        }
                        {
                            !aggregateData && DASHBOARD_CARD_DATA.map((item:any)=> (
                            <DashboardCard 
                                key={item.status}
                                status={item.status} 
                                total_request={item.total_requests} 
                                total_assets={item.total_assets}
                                is_loading={isGettingAggregate||isRefetchingAggregates}
                                onClick={(filter)=>navigate('/requests',{state:{filter}})} 
                            />
                        ))
                        }
                    </Box>
                </Box>
                <Box sx={{marginTop:'40px', marginBottom:'30px'}}>
                    <Typography sx={{fontWeight:'bold', fontSize:'24px'}}>Requests received</Typography>
                    <Typography sx={{fontSize:'14px', opacity:0.6}}>This is a list of all requests you’ve received.</Typography>
                </Box>
                <Box>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        marginBottom:'10px'
                    }}>
                        <APStatusFilter filters={filters} onStatusClick={handleFilterClicked} />
                        <Box>
                            {/* <APIconButton 
                                text="New Request"
                                onClick={()=>{navigate('/new-request')}}
                                icon={<AddIcon />}
                            /> */}
                        </Box>
                    </Box>
                    <Box sx={{height:370}}>
                        <DashboardTable data={reqData} onDelete={handleSingleDelete} isLoading={isRefetching}/>
                    </Box>
                </Box>
            </Box>
            <DeleteConfirmationDialog
                title="Move to trash"
                content={'Are you sure you want to move these requests to trash?'}
                open={openDeleteDialog}
                onCancel={handleCancelDelete}
                onDelete={()=>{deleteRequestMutation(selectedRow?.id);}}
                isDeleting={isRequestDeleting}
            />
        </Box>
    );

}

export default DashboardPage;