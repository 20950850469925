import { Box, Typography, useTheme } from "@mui/material";
import IconText from "./IconText";
import { LabelInput } from "../../components/inputs";
import { useForm } from "react-hook-form";
interface Props{
    image: string;
    summary: string;
    location: string;
    valuationType: string;
    serial: string;
    milage: string;
    description: string;
    type:'main'|'compare',
    amount?:string;
}
const AssetItem:React.FC<Props> = ({image, summary, location, valuationType,serial,milage,description,type,amount}) => {
    const theme = useTheme()
    const {control} = useForm();
    return(
        <Box sx={{display:'flex',gap:'20px'}}>
            <Box
                sx={{
                    width:'250px',
                    height:'270px'
                }}
            >
                <img src={image} alt={image} width={'100%'} height={'100%'}/>
            </Box>
            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'space-around'}}>
                <Box>
                    <Typography>Summary</Typography>
                    <Typography sx={{fontSize:'16px', color:`${theme.palette.black}60`}}>{summary}</Typography>
                </Box>
                <Box>
                    <Box sx={{display:'flex', items:'center', mb:'6px', gap:'30px'}}>
                        <IconText text={location} icon={'location_on'}/>
                        <IconText text={valuationType} icon={'attach_money'} />
                    </Box>
                    <Box sx={{display:'flex', items:'center',mb:'6px', gap:'30px'}}>
                        <IconText text={serial} icon={'directions_car'}/>
                        <IconText text={milage} icon={'speed'} />
                    </Box>
                    <Box sx={{display:'flex', items:'center'}}>
                        <IconText text={description} icon={'settings'}/>
                    </Box>
                </Box>

                <Box>
                    {type==='main' && (
                        <LabelInput 
                            label="Enter estimte ($)"
                            name="estimate"
                            control={control}
                        />
                    )}
                    {type === 'compare' && (
                        <Box>
                            <Typography sx={{fontSize:'12px', color:`${theme.palette.black}60`}}>Appraised price ($)</Typography>
                            <Typography sx={{fontSize:'16px', color:`${theme.palette.black}90`, fontWeight:'bold'}}>{amount}</Typography>
                        </Box>
                    )}
                </Box>

            </Box>
        </Box>
    )

}

export default AssetItem;