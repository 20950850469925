import Button from "@mui/material/Button";
import { appTheme } from "../themes/theme";
import { SelectInput } from "../components/inputs";
import { EVALUATION_REASONS } from "../data/selectData";
import { useForm } from "react-hook-form";
import LabelCheckbox from "./inputs/LabelCheckbox";
import LabelRadio from "./inputs/LabelRadio";

const DesignSystemPage = () => {
  const {control} = useForm();
    return (
        <div style={{margin:'2rem'}}>
          {/* COLORS */}
          <div>
            <p>Colors</p>
            <div >
            <Button color="primary" variant="contained">
              Primary
            </Button>
            <Button color="secondary" variant="contained">
              Secondary
            </Button>
            <Button color="success" variant="contained">
              Success
            </Button>
            <Button color="error" variant="contained">
              Error
            </Button>
            <Button color="warning" variant="contained">
              Warning
            </Button>
            <Button sx={{backgroundColor: appTheme.palette.cerulean_blue}} variant="contained">
              Cerulean Blue
            </Button>
            <Button sx={{backgroundColor: appTheme.palette.purple_illusionist}} variant="contained">
              Purple Illusionist
            </Button>
          </div>

          </div>

        {/* COLORS */}
        <p>Inputs</p>
        <div>
          <div style={{width:'350px'}}>
            <SelectInput label="Single Select Input" name="select-input" control={control} options={EVALUATION_REASONS}/>
            <SelectInput label="Multi Select Input" name="select-input" control={control} options={EVALUATION_REASONS} isMultiple/>
          </div>
            <div>
              <LabelCheckbox
                label="Checkbox (Row Layout)"
                name="preferences-row"
                control={control}
                options={[
                  { label: 'FLV', value: 'FLV' },
                  { label: 'FMV', value: 'FMV' },
                  { label: 'OLV', value: 'OLV' },
                ]}
              />
              <LabelCheckbox
                label="Checkbox (Column Layout)"
                name="preferences-column"
                layout="column"
                control={control}
                options={[
                  { label: 'FLV', value: 'FLV' },
                  { label: 'FMV', value: 'FMV' },
                  { label: 'OLV', value: 'OLV' },
                ]}
              />
            </div>
            <div>
            <LabelRadio
              label="Radio (Row Layout)"
              name="favoriteColorRow"
              control={control}
              defaultValue="red"
              options={[
                { label: 'Red', value: 'red' },
                { label: 'Blue', value: 'blue' },
                { label: 'Green', value: 'green' },
              ]}
            />

            {/* Display radio buttons in a column */}
            <LabelRadio
              label="Radio (Column Layout)"
              name="favoriteColor"
              control={control}
              defaultValue="blue"
              options={[
                { label: 'Red', value: 'red' },
                { label: 'Blue', value: 'blue' },
                { label: 'Green', value: 'green' },
              ]}
              layout="column"
            />
            </div>
        </div>
        </div>
    )
}

export default DesignSystemPage;