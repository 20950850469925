// src/components/layouts/AuthLayout.tsx
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid, Paper, Typography } from '@mui/material';
import AppLogo from '../../components/AppLogo';
import { useAuthStore } from './authStore';
import { useGeneralStore } from '../../generaStore';

const AuthLayout: React.FC = () => {
  const location = useLocation();
  const isLoggedIn = useAuthStore(state=>state.isLoggedIn);
  const setIsPageTransition = useGeneralStore(state=>state.setIsPageTransition);
  const navigate = useNavigate();
  const backgroundImageUrl = (() => {
    switch (location.pathname) {
      case '/login':
        return '/assets/images/login-2.jpg';
      case '/forgot-password':
        return '/assets/images/reset-password.png';
      case '/reset-password':
        return '/assets/images/set-password.png';
      case '/set-password':
        return '/assets/images/set-password.png';
      case '/confirm-email':
        return '/assets/images/reset-password.png';
      default:
        return '/assets/images/login-2.jpg';
    }
  })();

  useEffect(()=>{
    if(isLoggedIn){
      setIsPageTransition(true);
      navigate('/');
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isLoggedIn])

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={9}
        sx={{
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={3} component={Paper} square>
        <Box
          sx={{
            px: 4,
            py: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            height:'90%'
          }}
        >
          <Box sx={{ mb: 5, }}>
           <AppLogo />
          </Box>
          <Outlet />
        </Box>
        <Typography
          sx={{ textAlign: 'center', width: '100%', fontSize: '12px' }}
        >
          ©Appraisal Service 2024
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
