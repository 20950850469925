import Box from "@mui/material/Box";
import APDivider from "../../components/APDivider";
import { RadioInput } from "../../components/inputs";
import { Control } from "react-hook-form";
import { RESTRICTION_TYPE_DATA } from "../../data/selectData";

interface Props{
    control: Control<any>;
}

const RestrictedType:React.FC<Props> = ({control}) => {
    return (
        <Box>
            <APDivider text="Appraisal report type" margin="40px 0px"/>
                <RadioInput
                    name="restricted"
                    control={control}
                    options={RESTRICTION_TYPE_DATA}
              />
        </Box>
    )
}
export default RestrictedType;