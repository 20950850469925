import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useFetchUsers } from "../../hooks/useFetchUsers";
import UserForm from "./UserForm";
import { useGeneralStore } from "../../generaStore";
import DeleteConfirmationDialog from "../../components/dialogs/DeleteConfirmationDialog";
import { useMutation } from "@tanstack/react-query";
import { deleteUser } from "./userQueries";
import { toast } from "react-toastify";
import { handleError } from "../../utils/errorHandler";
import { IPagination, ITableFilter } from "../../interfaces";
import UserTable from "./UserTable";
import UserTableActions from "./UserTableAction";

const UserManagementPage = () => {
    const setPageTransition = useGeneralStore((state) => state.setIsPageTransition);
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [tableData, setTableData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [tableFilters,setTableFilters] = useState<ITableFilter[]>([]);
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const [contentText, setContentText] = useState<string>('');
    const [hideActions, setHideActions] = useState(false);
    const [prevResults, setPrevResults] = useState<any[] | undefined>(undefined);
    const [params, setParams] = useState({limit:10});
    
    const { data: response, isPending: isLoading, isRefetching, refetch, isError, error } = useFetchUsers(params);
    const { isPending: isUserDeleting, mutate: deleteUserMution } = useMutation({
        mutationKey: ['delete-user'],
        mutationFn: (userId: string) => deleteUser(userId),
    });

    const handleStatusChange = (status: string) => {
        if(status === 'all') {
            setTableFilters([]);
            return;
        }
        setTableFilters((prevFilters) => {
            const existingFilter = prevFilters.find(filter => filter.field === 'status');
    
            if (existingFilter) {
                return prevFilters.map(filter =>
                    filter.field === 'status'
                        ? { ...filter, value: status }
                        : filter
                );
            } else {
                return [...prevFilters, { field: 'status', operator: 'equals', value: status }];
            }
        });
    };

    const handleSearchResult = useCallback((results: any) => {
        console.log(results)
        if (results) {
            setTableData(results.data);
            setTotalCount(results.meta.total);
        } else if (results === undefined && prevResults && prevResults.length === 0) {
            refetch();
        }
        
        // Update the previous results
        setPrevResults(results);
    }, [refetch, prevResults]);

    const handleCloseForm = (event: any) => {
        setSelectedRow(null);
        setOpenForm(false);
      };
    
    const handleOnDelete = () => {
        if(selectedRowIds.length === 2 || selectedRowIds.length >2){
            setOpenDeleteDialog(false);
        }
        else {
            deleteUserMution(selectedRow.id, {
                onSuccess: () => {
                    toast.success('Successfully moved to trash');
                    refetch();
                    setOpenDeleteDialog(false);
                },
                onError: (error) => {
                    handleError(error);
                }
            });
        }
    };

    const handleSingleDelete = (val:any) => {
        setContentText(val.content);
        setSelectedRow(val.row);
        setOpenDeleteDialog(true);
    }

    const handleRowCheck = (item:any,details:any) => {
        if(item.length === 2 || item.lenght > 2 ){
            console.log('hide actions')
            setHideActions(true);
        }
        else if(item.length < 2){
            setHideActions(false)
        }
        setSelectedRowIds(item);
    }

    const handleCancelDelete = () => {
        setSelectedRow(null);
        setOpenDeleteDialog(false);
    };
    const handleBulkDelete = () => {
        setContentText('Are you sure you want to move this selected to trash?');
        setOpenDeleteDialog(true);
    }

    const handlePageChange = (page:IPagination) => {
        setParams((prev:any)=>({...prev,page:page.page+1}));
    }

    useEffect(() => {
        setPageTransition(isLoading);
        if (!isLoading && response) {
          setTableData(response.data);
          setTotalCount(response.meta.total);
        }
        if(isError){
            handleError(error);
        }
    
        return () => {
          setPageTransition(false);
        };
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isLoading, isRefetching, isError]);

      const handleOrgSelect= (org:any)=>{
        console.log(org);
        if(org.id === 'all'){
            setParams((prev:any)=>{
                delete prev.organization_id
                return {...prev};
            })

            return
        }

        setParams((prev)=>({...prev,organization_id:org.id}))
      }

    return (
        <Box sx={{ width: '100%', padding:theme=> theme.spacing(3) }}>
            <UserTableActions 
                selectedRowIds={selectedRowIds}
                onBulkDelete={handleBulkDelete}
                onOpenForm={()=>setOpenForm(true)}
                onStatusChange={handleStatusChange}
                onSearchResult={handleSearchResult}
                onOrgSelect={handleOrgSelect}
            />
            <UserTable  
                tableData={tableData}
                totalRows={totalCount}
                tableFilters={tableFilters}
                isLoading={isLoading}
                hideActions={hideActions}
                onRefetch={refetch}
                onRowCheck={handleRowCheck}
                onOpenForm={(row)=>{
                    setSelectedRow(row);
                    setOpenForm(true)
                }}
                onDelete={handleSingleDelete}
                onPageChange={handlePageChange}

            />
            <UserForm open={openForm} onClose={handleCloseForm} selectedRow={selectedRow} refetchUsers={refetch}/>
            <DeleteConfirmationDialog
                title="Move to trash"
                content={contentText}
                open={openDeleteDialog}
                onCancel={handleCancelDelete}
                onDelete={handleOnDelete}
                isDeleting={isUserDeleting}
            />
        </Box>
    );
};

export default UserManagementPage;
