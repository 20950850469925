import { styled } from "@mui/material/styles";
import { ReactNode } from "react";
import { DRAWER_WIDTH } from "../Constant";

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
  }>(({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${DRAWER_WIDTH}px`,
    }),
  }));

interface Props {
    open: boolean;
    children?: ReactNode; // To allow nested content
}

const MainContent: React.FC<Props> = ({ children, open }) => (
  <Main open={open} sx={{ backgroundColor: '#FFFCFD' }}>
    {children}
  </Main>
);

export default MainContent;
