import { Box } from '@mui/material'
import React from 'react'
import APDivider from '../../components/APDivider'
import { Control } from 'react-hook-form';
import { RichTextInput } from '../../components/inputs';

interface Props{
  control: Control<any>;
}

const LimitingConditions:React.FC<Props> = ({control}) => {
  return (
    <Box>
      <APDivider text='Limiting conditions' margin="40px 0px"/>
      <RichTextInput id="limiting_conditions" name="limiting_conditions" control={control} />
    </Box>
  )
}

export default LimitingConditions