export const getAbbreviation = (label: string): string => {
    if(!label) return '';
    const words = label.split(' ').filter(Boolean); // Split label by spaces and remove empty strings
  
    if (words.length === 1) {
      // If only one word, return the first character
      return words[0][0].toUpperCase();
    }
  
    if (words.length === 2) {
      // If two words, return the first character of both
      return `${words[0][0]}${words[1][0]}`.toUpperCase();
    }
  
    // If more than two words, return the first character of the first and last words
    return `${words[0][0]}${words[words.length - 1][0]}`.toUpperCase();
  };

export const getCurrentDate = (): string => {
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
    const day = today.getDate().toString().padStart(2, "0");
    const year = today.getFullYear();
  
    return `${month}/${day}/${year}`;
  };