import { CustomAxiosRequestConfig, appAPI } from "../../httpClient";

export const createReport = async(requestId:string) => appAPI.get(`/requests/${requestId}/report`);
export const updateReport = async(requestId:string,data:any) => appAPI.patch(`/requests/${requestId}/report`,data);
export const generateReport = async(requestId:string,file:Blob)=>{
    // const temData = {name:data.name,};
    const formData = new FormData();
    // formData.append('report[name]', data.name);
    // formData.append('owner', data.owner.value);
    // if (data.file instanceof File) {
    // }
    formData.append('document',file, 'report.pdf');

    return appAPI.post(`/requests/${requestId}/upload_report`,formData,{
        headers:{
            'Content-Type': 'multipart/form-data', 
        }
    } as CustomAxiosRequestConfig);
}