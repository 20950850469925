import { Box, Typography } from "@mui/material";
import { MaterialSymbol } from "react-material-symbols";

interface Props{
    searchPlusAction?: ()=>void;
}

const SearchPlus:React.FC<Props> = ({searchPlusAction}) => {
    return (
        <Box 
        sx={{
            width:'136px',
            display:'flex',
            alignItems:'center',
            justifyContent:'space-between',
            backgroundColor: 'white',
            borderRadius: '48px',  
            border: '1px solid #f0f0f0',
            height:'42px',
            p:'8px 16px',
            ":hover":{
            cursor:'pointer'
            }
        }}
        onClick={searchPlusAction}
        >
        <Typography sx={{fontSize:'',fontWeight:'500'}}>Search plus</Typography>
        <MaterialSymbol  icon="search" size={16}/>
        </Box>
    )

}

export default SearchPlus;