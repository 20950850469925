import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {CircularProgress} from '@mui/material';

interface Props{
    title:string;
    content:string;
    open: boolean;
    onDelete?:()=>void;
    onCancel?:()=>void;
    isDeleting?:boolean;
}
const DeleteConfirmationDialog:React.FC<Props> = ({title, content,open, isDeleting, onDelete, onCancel}) =>{

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>CANCEL</Button>
        <Button 
          onClick={onDelete} 
          disabled={isDeleting}
          endIcon={isDeleting? <CircularProgress color="inherit" size={20} sx={{marginRight:1}} />:null}
        >
          {isDeleting?'MOVING':'MOVE'} TO TRASH
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteConfirmationDialog;
