import { useMutation } from "@tanstack/react-query";
import { deleteRequest } from "../features/requests/requestQueries";
import { toast } from "react-toastify";
import { handleError } from "../utils/errorHandler";

interface UseDeleteRequestProps {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
  refetch?: () => void;
}

const useDeleteRequest = ({ onSuccess, onError, refetch }: UseDeleteRequestProps) => {
  return useMutation({
    mutationFn: (requestId: string) => deleteRequest(requestId),
    onSuccess: () => {
      toast.success('Successfully moved to trash');
      if (refetch) refetch();
      if (onSuccess) onSuccess();
    },
    onError: (error) => {
      if (onError) {
        onError(error);
        return;
      }
      handleError(error);
    },
  });
};

export default useDeleteRequest;
