import { persist } from 'zustand/middleware';
import { create } from 'zustand';

interface RequestStore {
  selectedRequest: any;
}

interface RequestAction {
  storeSelectedRequest: (data: any) => void;
}

const initialState: RequestStore = {
  selectedRequest: null
};

export const useRequestStore = create<RequestStore & RequestAction>()(
  persist(
    (set) => ({
      ...initialState,
      storeSelectedRequest: (response: any) =>
        set(() => {
          return {
            selectedRequest: response
          };
        }),
    }),
    {
      name: 'request-storage'
    }
  )
);
