import { Box, Typography, } from '@mui/material';
import APFileButton from '../../buttons/APFileButton';
import React from 'react';

interface Props{
    // id?: string;
    label?: string;
    onChange?:(file:File)=>void;
    handleFileChange?: (file:File)=>void;
    onBlur?: any;
    accepts?:string;
    showModal?:boolean;
    isCircle?:boolean;
}
const APDragAndDrop: React.FC<Props> = ({label,accepts, isCircle, showModal, onBlur,onChange,handleFileChange}) => {
    return (
        <Box
            sx={{
                textAlign: 'center',
                cursor: 'pointer',
            }}
            onDrop={(e:any) => {
                e.preventDefault();
                const file = e.dataTransfer.files?.[0];
                if (file) {
                    if (onChange) onChange(file); // Update form state with file
                    if (handleFileChange) handleFileChange(file);
                }
            }}
            onDragOver={(e:any) => e.preventDefault()}
        >
            <Typography variant="body1" color="primary" fontSize={12}>
                {label}
            </Typography>
            <Typography variant="body2" color="#86888A" fontSize={12}>
                or
            </Typography>
            <APFileButton 
                accepts={accepts}
                onBlur={onBlur}
                isCircle={isCircle}
                onChange={onChange}
                handleFileChange={handleFileChange}
            />
        </Box>
    )
}

export default APDragAndDrop;