import { Avatar, Badge, Box, Typography, styled, Popover, useTheme } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, MouseEvent } from "react";

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 3,
    top: 4,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

interface Props{
  data?:any[];
}

const APImageBadge:React.FC<Props> = ({data=[]}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    if (data.length > 1) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{display: 'flex', alignItems: 'center', width:'100%', height: '100%'}}>
      {
        data.length>0 ?(
          <Box
            sx={{ display: 'flex', alignItems: 'center', width:'100%', height: '100%', gap: '8px' }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <StyledBadge
              overlap="circular"
              badgeContent={data.length>1?data.length:null}
              color="error"
            >
              <Avatar alt={data?.[0].image} src={data?.[0].image} sx={{ height: '32px', width: '32px' }} />
            </StyledBadge>
            <Box sx={{width:'100%'}}>
              <Box sx={{ display: 'flex', alignItems: 'center', width:'100%', gap:1 }}>
                <Typography sx={{ fontSize: '10px', color: '#86888A' }}>{data[0].name}</Typography>
                {data.length > 1 && <ExpandMoreIcon sx={{ height: '16px', width: '16px', padding: 0, color: '#323232' }} />}
              </Box>
              <Typography sx={{ fontSize: '14px', color: '#343434' }}>{data[0].estimate}</Typography>
            </Box>
            
            <Popover
              sx={{
                pointerEvents: 'none',
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Box sx={{ p: '16px', borderRadius:'16px' }}>
                  {data.filter((item:any)=>item.name !== data[0].name).map((user:any, index:number) => (
                    <Box key={user.name} sx={{display:'flex', alignItems:'center', gap:1, height:'36px'}}>
                      <Avatar sx={{width:'20px', height:'20px'}} src={user.image} />
                      <Typography sx={{color:'#86888A', fontSize:'12px'}}>{user.name}</Typography>
                      <Box sx={{width:'6px', height:'2px', backgroundColor:'#86888A'}}/>
                      <Typography sx={{color:theme.palette.primary.main, fontSize:'12px', fontWeight:'bold'}}>{user.estimate}</Typography>
                    </Box>
                  ))}
              </Box>
            </Popover>
          </Box>

        ):(
          <Box></Box>
        )
      }
    </Box>
  );
};

export default APImageBadge;
